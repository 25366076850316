import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { compose, prop, pick, assoc, over, lensProp, findIndex, propEq, remove, trim } from 'ramda';

import { Formik, Form } from 'formik';
import { Grid, Collapse, Switch, FormControlLabel, Typography, TextField, Chip, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';

import Drawer from '../Drawer';
import Loader from '../Loader';
import Select from '../Select';
import Input from '../Input';
import Button from '../Button';
import ButtonConfirmation from '../ButtonConfirmation';
import ExportPDF from '../ExportPDF';
import SingleDatePicker from '../SingleDatePicker';
import WarningMessage from '../WarningMessage';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import {
  loadReceiptInvoicePrint,
  loadInvoiceDetails,
  upsertInvoice,
  payInvoice,
  undoPayment,
  emailInvoice,
  changeInvoiceSituation
} from '../../pages/ClientInvoices/store/thunk';

import {
  clearForm,
  handlePaying,
  handleEmailing,
  handleCanceling,
  handleCancelingPayment
} from '../../pages/ClientInvoices/store/actions';

import {
  convertToOptions,
  convertAmountToDB,
  convertInvoicePrint,
  convertToEmptyString,
  convertDateToDB,
  convertDateAndTimeToDB
} from '../../helpers/converters';

import { fetchUsersSituation } from 'components/Filter/store/services';
import { loadPaymentMethods } from 'pages/PaymentMethods/store/thunk';
import { setRpsInfo } from 'pages/ProvisionalReceipt/store/actions';
import { loadNfseConcept } from 'pages/ProvisionalReceipt/store/thunk';
import { sendNfse } from 'pages/ProvisionalReceiptConfig/store/thunk';

import { validations } from './validations';

export default function Invoice({ isOpen, pageOrigin, establishmentId, transactionsQp, transactionsPage, transactionsPageSize }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { token } = useSelector(state => state.login);
  const { activePage: page, size: pageSize } = useSelector(state => state.pagination);
  const { selects, qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const clientId = useSelector(state => state.clientRegistration.basicInfo.clientId);
  const clientName = useSelector(state => state.clientRegistration.basicInfo.submit.name);
  const { penalty, penaltyType } = useSelector(state => state.clientRegistration.charges.submit);
  const { isSendNfseLoading } = useSelector(state => state.provisionalReceiptConfig);

  const availablePaymentMethods = [
    {
      label: '--',
      value: ''
    },
    ...useSelector(state => state.paymentMethods.list)?.map(paymentMethod => {
      return {
        label: paymentMethod?.paymentMethodName,
        value: paymentMethod?.establishmentPaymentMethodId
      }
    })
  ];

  const accounts = convertToOptions(['account'], selects);
  const findCashier = findIndex(propEq('label', 'Caixa'));
  const availableAccountsFilter = compose(remove(findCashier, 1), remove(0, 1));
  const availableAccounts = availableAccountsFilter(accounts);

  const {
    isDetailing,
    isPaying,
    isEmailing,
    isCanceling,
    isCancelingPayment,
    invoiceDateTime,
    submit,
    submit: {
      situationId,
      invoiceSituation,
      paymentDateTime,
      dueDateTime,
      userOutputName,
      accountId,
      transactionId
    }
  } = useSelector(state => state.clientInvoices);

  const {
    sourceId,
    isNfInfo,
    nfInfo: {
      rpsSeries,
      rpsNumber,
      nfNumber
    } = {}
  } = useSelector(state => state.provisionalReceipt);

  const isFiveDaysPastFromPayment =
    moment(moment.now()).diff(moment(paymentDateTime, 'YYYY-MM-DD'), 'days') > 5;

  const [confirmResendNfse, setConfirmResendNfse] = useState(false);
  const [taxSettings, setTaxSettings] = useState(0);
  const [dueDateTimeWhenPaying, setDueDateTimeWhenPaying] = useState('');

  const handleLoadInvoiceDetails = () => {
    return dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));
  }

  useEffect(() => {
    (async () => {
      if(submit?.establishmentId) {
        const { data } = await fetchUsersSituation(userId, token);

        const establishmentTaxSettings = data?.establishments
          ?.find(establishment => +establishment?.establishmentId == +submit?.establishmentId)
          ?.taxSettings;

        setTaxSettings(establishmentTaxSettings);
      }
    })();
  }, [submit?.establishmentId]);

  useEffect(() => {
    handleLoadInvoiceDetails();
    dispatch(loadPaymentMethods({ userId, establishmentId }));
  }, []);

  useEffect(() => {
    dispatch(setRpsInfo({
      rpsSeries: '',
      rpsNumber: '',
      nfNumber: '',
      nfStatus: ''
    }));

    const id = `${clientId}${invoiceDateTime}`;

    if(+taxSettings == 1 && !!transactionId && (+situationId == 2 || +situationId == 3)) {
      if(sourceId || id) {
        dispatch(loadNfseConcept(userId, establishmentId, sourceId || id));
      }
    }
  }, [taxSettings, transactionId, situationId]);

  const handlePrintInvoice = () => {
    const params = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime
    }

    return dispatch(loadReceiptInvoicePrint(params));
  }

  const handleModal = () => {
    dispatch(clearForm());
  }

  const togglePaymentOptions = () => {
    dispatch(handlePaying());
  }

  const toggleSendInvoice = () => {
    dispatch(handleEmailing());
  }

  const handleSendInvoice = ({ email }) => {
    dispatch(emailInvoice(userId, establishmentId, clientId, invoiceDateTime, { email }));
  }

  const toggleCancelInvoice = () => {
    dispatch(handleCanceling());
  }

  const toggleCancelPayment = () => {
    dispatch(handleCancelingPayment());
  }

  const handleCancelInvoice = ({ userPassword, reason }) => {
    const params = {
      userPassword,
      reason,
      situationId
    }

    const properties = {
      page: pageOrigin == 'transactions' ? transactionsPage : page,
      pageSize: pageOrigin == 'transactions' ? transactionsPageSize : pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp: pageOrigin == 'transactions' ? transactionsQp : qp
    }

    dispatch(changeInvoiceSituation(userId, establishmentId, clientId, invoiceDateTime, params, properties));
  }

  const handlePayment = (
    {
      amount,
      amountReceived,
      paymentDateTime,
      paymentMethod,
      dueDateTime,
      currentAmount,
    },
    { resetForm }
  ) => {
    const convertDateToDB = date => `${date} 00:00:01`;

    const handleAmountToSend = amount =>
      amountReceived
        ? convertAmountToDB(amountReceived)
        : convertAmountToDB(amount);

    if(!moment(paymentDateTime).isValid()) {
      return toast.error('Selecione uma data de pagamento válida.');
    }

    const formattedPaymentDateTime =
      paymentDateTime == '0001-01-01' ||
      paymentDateTime == '0001-01-01 00:00:00' ||
      paymentDateTime == 'Data inválida' ||
      paymentDateTime == 'Invalid date'
        ? moment().format('YYYY-MM-DD HH:mm:ss')
        : paymentDateTime;

    const params = isPaying
      ? {
          amount: handleAmountToSend(handleAmountToSend(amount)),
          paymentDateTime: convertDateAndTimeToDB(formattedPaymentDateTime),
          paymentMethod,
        }
      : {
          situationId,
          amount: convertAmountToDB(currentAmount),
          dueDateTime: convertDateToDB(dueDateTime),
        }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page,
      pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp
    }

    if(isPaying) {
      dispatch(payInvoice(params, properties)).then(error => {
        if(!error) {
          resetForm();
        }
      });
    } else {
      dispatch(upsertInvoice(params, properties)).then(error => {
        if(!error) {
          resetForm();
        }
      });
    }
  }

  const handleCancelPayment = ({ reason, userPassword }, { resetForm }) => {
    const params = {
      situationId: 1,
      reason,
      userPassword
    }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page: pageOrigin == 'transactions' ? transactionsPage : page,
      pageSize: pageOrigin == 'transactions' ? transactionsPageSize : pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp: pageOrigin == 'transactions' ? transactionsQp : qp
    }

    return dispatch(undoPayment(params, properties)).then(error => {
      if(!error) {
        resetForm();
      }
    });
  }

  const handleSubmit = (values, actions) => {
    if(!isEmailing && !isCanceling && !isCancelingPayment) {
      return dispatch(handlePayment(values, actions));
    }

    if(isEmailing) {
      return dispatch(handleSendInvoice(values, actions));
    }

    if(isCancelingPayment) {
      return dispatch(handleCancelPayment(values, actions));
    }

    return dispatch(handleCancelInvoice(values, actions));
  }

  const handleSaveButton = () => {
    if(isPaying) {
      return 'Pagar fatura';
    }

    if(invoiceSituation === 'Paga') {
      return 'Desfazer pagamento';
    }

    return 'Salvar';
  }

  const getChipStyle = () => {
    if(invoiceSituation === 'Cancelada') {
      return {
        color: '#F44336',
        backgroundColor: 'rgba(244, 67, 54, 0.2)'
      }
    }

    if(invoiceSituation === 'Paga') {
      return {
        color: '#4CAF50',
        backgroundColor: 'rgba(76, 175, 80, 0.2)'
      }
    }

    return {
      color: '#FF9800',
      backgroundColor: 'rgba(255, 152, 0, 0.2)'
    }
  }

  const labelStatus = () => {
    return(
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Typography variant='body1'>
          Detalhamento da fatura
        </Typography>
        <Chip
          label={invoiceSituation}
          style={getChipStyle()}
        />
      </div>
    );
  }

  const initialValues = compose(
    over(lensProp('paymentMethod'), convertToEmptyString),
    over(lensProp('email'), trim),
    assoc('isPaying', isPaying),
    assoc('isEmailing', isEmailing),
    assoc('isCanceling', isCanceling),
    assoc('isCancelingPayment', isCancelingPayment),
    pick([
      'dueDateTime',
      'currentDueDateTime',
      'paymentMethod',
      'paymentDateTime',
      'amount',
      'amountReceived',
      'currentAmount',
      'email',
      'reason',
      'userPassword',
      'clientName'
    ]),
    prop('submit')
  );

  const invoicePaid = invoiceSituation === 'Paga' || invoiceSituation === 'Cancelada';

  return (
    <>
      <Drawer
        id="accounts"
        title={labelStatus()}
        open={isOpen}
        onClose={handleModal}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues(useSelector(state => state.clientInvoices))}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => (
            <Form style={{ height: '100%' }}>
              <Loader isLoading={isDetailing}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Input
                          disabled
                          id="clientName"
                          name="clientName"
                          label="Cliente"
                          value={clientName || formikProps.values.clientName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {formikProps.values.isPaying ? (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              disabled
                              inputFormat="DD/MM/YYYY"
                              label="Data do vencimento"
                              value={dueDateTimeWhenPaying}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name="dueDateTime"
                                  variant='outlined'
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      disableEnforceFocus: true
                                    }
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        ) : (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              disabled={invoicePaid || formikProps.values.isPaying}
                              inputFormat="DD/MM/YYYY"
                              label="Data do vencimento"
                              value={formikProps.values.dueDateTime || dueDateTime}
                              onChange={date => formikProps.setFieldValue('dueDateTime', convertDateToDB(date))}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  name="dueDateTime"
                                  variant='outlined'
                                  inputProps={{
                                    ...params.inputProps
                                  }}
                                  SelectProps={{
                                    MenuProps: {
                                      disableEnforceFocus: true
                                    }
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {!!transactionId && (
                        <Grid item xs={12}>
                          <Select
                            disabled
                            id="account"
                            name="account"
                            label="Selecione a conta destino"
                            value={
                              formikProps.values.account ||
                              accountId ||
                              (availableAccounts.length > 0 ? availableAccounts[0].value : '')
                            }
                            options={accounts}
                            error={formikProps.errors.account}
                            helperText={formikProps.errors.account}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                      )}
                      <Grid item xs={6}>
                        <Input
                          disabled
                          id="penaltyType"
                          name="penaltyType"
                          label={penaltyType === 1 ? 'Multa (R$)' : 'Multa (%)'}
                          value={penalty}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          id="currentAmount"
                          name="currentAmount"
                          label="Valor"
                          type="money"
                          disabled={isPaying || invoicePaid}
                          value={formikProps.values.currentAmount}
                          error={formikProps.errors.currentAmount}
                          helperText={formikProps.errors.currentAmount}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <hr />
                    <Grid container style={{ marginBottom: 10 }}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          id="invoiceSituation"
                          name="invoiceSituation"
                          label="Recebido"
                          control={
                            <Switch
                              color="primary"
                              disabled={invoicePaid}
                              onChange={() => {
                                setDueDateTimeWhenPaying(formikProps.initialValues.dueDateTime);
                                return togglePaymentOptions();
                              }}
                              checked={formikProps.values.isPaying || invoiceSituation === 'Paga'}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                      >
                        {situationId === 2 && (
                          <Grid item>
                            <Typography>Recebido por {userOutputName} ás {moment(paymentDateTime).format('DD/MM/YYYY HH:mm')}</Typography>
                          </Grid>
                        )}
                        {(invoiceSituation === 'Paga' && rpsNumber > 0) && (
                          <Grid item>
                            <Typography>RPS: {rpsSeries}-{rpsNumber} | N˚ NFSe: {nfNumber}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Collapse in={isPaying || invoiceSituation === 'Paga'}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SingleDatePicker
                            name="paymentDateTime"
                            label="Data do recebimento"
                            disabled={invoicePaid}
                            initialDate={
                              moment(formikProps.values.paymentDateTime).format('DD/MM/YYYY') != '01/01/0001' &&
                              moment(paymentDateTime).format('DD/MM/YYYY') != '01/01/0001'
                                ? (formikProps.values.paymentDateTime || paymentDateTime)
                                : moment().format('YYYY-MM-DD')
                            }
                            onDateChange={date => formikProps.setFieldValue('paymentDateTime', convertDateToDB(date))}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Select
                            id="paymentMethod"
                            name="paymentMethod"
                            label="Selecione o meio de pagamento"
                            disabled={invoicePaid}
                            options={availablePaymentMethods}
                            error={isCanceling ? null : formikProps.errors.paymentMethod}
                            helperText={formikProps.errors.paymentMethod}
                            value={formikProps.values.paymentMethod}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            id="amountReceived"
                            name="amountReceived"
                            type="money"
                            label="Valor recebido"
                            placeholder="R$ 0,00"
                            disabled={invoicePaid}
                            value={formikProps.values.amountReceived}
                            error={formikProps.errors.amountReceived}
                            helperText={formikProps.errors.amountReceived}
                            onChange={formikProps.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ExportPDF
                            type="button"
                            exportType="receiptInvoice"
                            color="tertiary"
                            variant="outlined"
                            fileName="fatura"
                            disabled={!situationId}
                            loading={isDetailing}
                            formatComponentData={convertInvoicePrint}
                            requestData={handlePrintInvoice}
                            startIcon={
                              <FontAwesomeIcon
                                size='xs'
                                icon={faDownload}
                              />
                            }
                          >
                            Baixar fatura
                          </ExportPDF>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Grid container style={{ display: 'flex', gap: 10 }}>
                      <Grid item xs={12}>
                        <Collapse in={isEmailing}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="email"
                                name="email"
                                label="E-mail"
                                value={formikProps.values.email}
                                error={formikProps.errors.email}
                                helperText={formikProps.errors.email}
                                onChange={e => formikProps.setFieldValue('email', e.target.value)}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        tabIndex="-1"
                                        onClick={() => formikProps.setFieldValue('email', '')}
                                      >
                                        <Close />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                        <Collapse in={isCanceling}>
                          <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                              <Input
                                id="reason"
                                name="reason"
                                label={
                                  situationId === 3
                                    ? 'Motivo da restauração'
                                    : 'Motivo do cancelamento'
                                }
                                value={formikProps.values.reason}
                                error={formikProps.errors.reason}
                                helperText={formikProps.errors.reason}
                                onChange={formikProps.handleChange}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Input
                                id="userPassword"
                                type="password"
                                label="Confirme sua senha"
                                error={formikProps.errors.userPassword}
                                helperText={formikProps.errors.userPassword}
                                onChange={formikProps.handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                        <Collapse in={isCancelingPayment}>
                          <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                              <Input
                                id="reason"
                                label="Motivo do cancelamento"
                                error={formikProps.errors.reason}
                                helperText={formikProps.errors.reason}
                                onChange={formikProps.handleChange}
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Input
                                id="userPassword"
                                type="password"
                                label="Confirme sua senha"
                                error={formikProps.errors.userPassword}
                                helperText={formikProps.errors.userPassword}
                                onChange={formikProps.handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                      {isEmailing && (
                        <Grid item style={{ display: 'flex', gap: 10 }}>
                          <Button
                            color="error"
                            variant="outlined"
                            loading={isDetailing}
                            onClick={toggleSendInvoice}
                          >
                            Cancelar
                          </Button>
                          <Button
                            color="primary"
                            loading={isDetailing}
                          >
                            Enviar fatura
                          </Button>
                        </Grid>
                      )}
                      {isCanceling && (
                        <Grid item style={{ display: 'flex', gap: 10 }}>
                          <Button
                            color="error"
                            variant="outlined"
                            loading={isDetailing}
                            onClick={toggleCancelInvoice}
                          >
                            Cancelar
                          </Button>
                          <Button
                            color="primary"
                            className="mr-2"
                            loading={isDetailing}
                          >
                            Confirmar
                          </Button>
                        </Grid>
                      )}
                      {isCancelingPayment && (
                        <Grid item style={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
                          <Button
                            color="error"
                            variant="outlined"
                            loading={isDetailing}
                            onClick={toggleCancelPayment}
                          >
                            Cancelar
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            className="mr-2"
                            loading={isDetailing}
                          >
                            Confirmar
                          </Button>
                        </Grid>
                      )}
                      {(!isEmailing && !isCanceling && !isCancelingPayment) && (
                        <>
                          {situationId !== 2 && (
                            <Grid item>
                              <Button
                                color={situationId === 3 ? 'primary' : 'error'}
                                variant={situationId === 3 ? 'contained' : 'outlined'}
                                loading={isDetailing}
                                onClick={toggleCancelInvoice}
                              >
                                {situationId === 3 ? 'Restaurar' : 'Cancelar'} fatura
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              color="tertiary"
                              variant="outlined"
                              disabled={!situationId}
                              loading={isDetailing}
                              onClick={toggleSendInvoice}
                            >
                              Enviar fatura
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              color={invoiceSituation === 'Paga' ? 'error' : 'primary'}
                              variant={invoiceSituation === 'Paga' ? 'outlined' : 'contained'}
                              loading={isDetailing}
                              disabled={invoiceSituation === 'Cancelada'}
                              onClick={invoiceSituation === 'Paga' ? toggleCancelPayment : null}
                            >
                              {handleSaveButton()}
                            </Button>
                          </Grid>
                          {(!isNfInfo && !!transactionId) && (
                            <>
                              {isFiveDaysPastFromPayment ? (
                                <ButtonConfirmation
                                  title="Enviar NFS-e"
                                  color="success"
                                  loading={isSendNfseLoading}
                                  isButtonDisabled={!confirmResendNfse}
                                  modalTitle="Enviar NFS-e"
                                  modalText={
                                    <>
                                      <Typography color="primary">
                                        Tem certeza que deseja enviar a nota fiscal?
                                      </Typography>
                                      <br />
                                      {isFiveDaysPastFromPayment && (
                                        <Grid>
                                          <WarningMessage message="Atenção! Mais de 5 dias se passaram desde o pagamento, fique atento a uma possível cobrança de multa por parte da prefeitura." />
                                          <FormControlLabel
                                            labelPlacement="end"
                                            label={
                                              <Typography color="primary">
                                                Entendi
                                              </Typography>
                                            }
                                            control={
                                              <Checkbox
                                                value={confirmResendNfse}
                                                onChange={event => setConfirmResendNfse(event.target.checked)}
                                              />
                                            }
                                          />
                                        </Grid>
                                      )}
                                    </>
                                  }
                                  modalButtons={[
                                    {
                                      color: 'success',
                                      title: 'Sim',
                                      onClick: () => dispatch(sendNfse(userId, submit?.establishmentId, `${clientId}${invoiceDateTime}`, { resend: 1 }))
                                    }
                                  ]}
                                />
                              ) : (
                                <Button
                                  color="success"
                                  type="button"
                                  loading={isSendNfseLoading}
                                  onClick={() => dispatch(sendNfse(userId, submit?.establishmentId, `${clientId}${invoiceDateTime}`, { resend: 1 }))}
                                >
                                  Enviar NFS-e
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </div>
                </div>
              </Loader>
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  );
}

Invoice.propTypes = {
  pageOrigin: PropTypes.string,
  isOpen: PropTypes.bool.isRequired
};

Invoice.defaultProps = {
  pageOrigin: 'invoices'
};