import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FilterList from '@material-ui/icons/FilterList';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import { Button as MUIButton } from '@material-ui/core';
import Button from '../../Button';
import FilterFields from './FilterFields';
import ExportButtons from '../../ExportButtons';
import Dropdown from '../../Dropdown';
import NewExportButtons from '../../NewExportButtons';

import { find, propEq } from 'ramda';

import { handleFilterButtonClick } from '../../Filter/store/actions';

import {
  registerFieldChange,
  handleSearch,
  handleExporting,
  clearSearch,
  toggleAdvanced,
  persistQueryParamsSearch,
} from '../store/actions';

import { setIsConsumeList, setIsSellList } from 'pages/Products/store/actions';
import { setActivePage } from '../../Pagination/store/actions';
import { formatQuery } from '../../../helpers/formatters';
import { usePrevious } from '../../../helpers/hooks/usePrevious';
import { containerStyles, buttonStyles } from './styles';

const searchAdvancedFilter = find(propEq('advanced', true));

const Filter = ({
  actionButton,
  hasProductsButton,
  hasRemoveButton,
  hasLinkButton,
  linkButtonText,
  hasModalButton,
  toggleModal,
  inlineCreateForm,
  hasExportButton,
  exportType,
  itemsToRemove,
  handleToRemoveItems,
  inputText,
  onPropsTextChange,
  handleToRegisterItem,
  filter,
  filters,
  headerFilters,
  hasSearchTerm,
  fileName,
  exportHeaders,
  handlePageRequest,
  formatComponentData,
  insertTransactionSelect,
  handleRegistration,
  newExportButton,
  functionNewExportButton,
  alternativeExport,
  functionAlternativeExport,
  isDisabledXlsExport,
  isDisabledPdfExport,
  htmlExport,
  htmlExportLoading,
  htmlExportFunction
}) => {
  const dispatch = useDispatch();

  const [isFilterOpen, openFilter] = useState(false);

  const { establishmentId } = useSelector(state => state.businessInfo);
  const pageSize = useSelector(state => state.pagination.size);
  const { isConsumeList, isSellList } = useSelector(state => state.products);

  const {
    isSearching,
    isAdvanced,
    search,
    filtrate,
    isExporting
  } = useSelector(state => state.filters);

  const containerClasses = containerStyles();
  const buttonClasses = buttonStyles();
  const prevEstablishmentId = usePrevious(establishmentId);

  const fieldChange = registerFieldChange;
  const onTextChange = func => e => dispatch(func(e.target.id, e.target.value));

  useEffect(() => {
    dispatch(clearSearch());
    dispatch(handleExporting(false));

    return () => {
      dispatch(clearSearch());
    }
  }, []);

  useEffect(() => {
    if(prevEstablishmentId !== establishmentId) {
      if(!establishmentId) {
        dispatch(toggleAdvanced(false));
      }
    }
  });

  const onCancelSearch = () => {
    dispatch(clearSearch());
    dispatch(handleSearch(false));
    dispatch(setActivePage(1));
    handlePageRequest({ page: 1, pageSize, qp: '' });
  }

  const onSearch = () => {
    dispatch(handleFilterButtonClick(true));

    const query = formatQuery(search, filtrate);

    if(!!search || !!filtrate) {
      dispatch(handleSearch(true));
    }

    dispatch(setActivePage(1));
    dispatch(persistQueryParamsSearch(query));
    handlePageRequest({ page: 1, pageSize, qp: query });
  }

  return(
    <Grid container classes={containerClasses}>
      <Grid container md={inlineCreateForm ? 4 : 6} spacing={1}>
        {actionButton && (
          <Grid item style={{ marginBottom: 5 }}>
            {actionButton}
          </Grid>
        )}
        {hasProductsButton && (
          <Grid item style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
            <MUIButton
              style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              color='primary'
              variant={isConsumeList ? 'contained' : 'outlined'}
              onClick={() => {
                dispatch(setIsConsumeList(true));
                dispatch(setIsSellList(false));
              }}
            >
              Consumo
            </MUIButton>
            <MUIButton
              style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              color='primary'
              variant={isSellList ? 'contained' : 'outlined'}
              onClick={() => {
                dispatch(setIsConsumeList(false));
                dispatch(setIsSellList(true));
              }}
            >
              Venda
            </MUIButton>
          </Grid>
        )}
        {hasLinkButton && (
          <Grid item>
            <Link style={{ textDecoration: 'none' }} to={hasLinkButton}>
              <Button type="button" color="success" classes={buttonClasses}>
                {linkButtonText}
              </Button>
            </Link>
          </Grid>
        )}
        {newExportButton && (
          <Grid item>
            <NewExportButtons
              isExporting={isExporting}
              handleExportation={functionNewExportButton}
            />
          </Grid>
        )}
        {hasModalButton && (
          <Grid item>
            <Button
              type="button"
              color="success"
              onClick={toggleModal}
              classes={buttonClasses}
            >
              {hasModalButton}
            </Button>
          </Grid>
        )}
        {hasRemoveButton && (
          <Grid item>
            <Button
              color="error"
              classes={buttonClasses}
              disabled={itemsToRemove.length === 0}
              onClick={handleToRemoveItems}
            >
              Excluir
            </Button>
          </Grid>
        )}
        {hasExportButton && (
          <Grid item classes={buttonClasses}>
            <ExportButtons
              color="tertiary"
              variant="outlined"
              fileName={fileName}
              exportType={exportType}
              exportHeaders={exportHeaders}
              handlePageRequest={handlePageRequest}
              formatComponentData={formatComponentData}
            />
          </Grid>
        )}
        {htmlExport && (
          <Grid item style={{ padding: 5 }}>
            <Button
              color='success'
              type='button'
              loading={htmlExportLoading}
              onClick={htmlExportFunction}
            >
              Exportar
            </Button>
          </Grid>
        )}
        {insertTransactionSelect && (
          <Grid item classes={buttonClasses}>
            <Dropdown label="Criar lançamento">
              <Button
                fullWidth
                disableElevation
                color="inherit"
                onClick={() => handleRegistration(true, 'receipt')}
              >
                Novo recebimento
              </Button>
              <Button
                fullWidth
                disableElevation
                color="inherit"
                onClick={() => handleRegistration(true, 'payment')}
              >
                Novo pagamento
              </Button>
            </Dropdown>
          </Grid>
        )}
      </Grid>
      {inlineCreateForm && (
        <Grid md={4}>
          <Tooltip title="Adicione mais de um cartão. Ex: 0-10" placement="top">
            <OutlinedInput
              id="inputText"
              margin="dense"
              placeholder="Número do novo cartão"
              value={inputText}
              onChange={onPropsTextChange}
              onKeyPress={e => e.key === 'Enter' && handleToRegisterItem(e)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={handleToRegisterItem}>
                    <Add />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
        </Grid>
      )}
      {(headerFilters?.length > 0) && (
        <Grid container spacing={2}>
          {headerFilters?.map(filter => {
            return(
              <Grid item xs={6}>
                <FilterFields filters={[filter]} />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid container style={{ marginBottom: 10 }}>
        <Grid item xs={12}>
          {filter && (
            <Button fullWidth onClick={() => openFilter(!isFilterOpen)}>
              {isFilterOpen ? 'Fechar filtro' : 'Mais filtros'}
              <FilterList style={{ marginLeft: 10 }} />
            </Button>
          )}
        </Grid>
      </Grid>
      {isFilterOpen && (
        <div style={{ width: '100%', marginBottom: 10 }}>
          <Grid item xs={12}>
            <FilterFields filters={filters.filter(f => !f.advanced)} />
          </Grid>
          {isAdvanced && (
            <Grid item xs={12}>
              <FilterFields filters={filters.filter(f => f.advanced)} />
            </Grid>
          )}
          {searchAdvancedFilter(filters) && (
            <div style={{ width: '100%', marginBottom: 10 }}>
              <Button
                fullWidth
                disableRipple
                style={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#1E5168' }}
                onClick={() => dispatch(toggleAdvanced(!isAdvanced))}
              >
                Ver mais {isAdvanced ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </Button>
            </div>
          )}
          {isSearching ? (
            <Button
              fullWidth
              type="button"
              color="error"
              onClick={() => onCancelSearch()}
            >
              Desfazer filtro
            </Button>
          ) : (
            <Button
              fullWidth
              type="button"
              color="success"
              onClick={() => onSearch()}
            >
              Filtrar
            </Button>
          )}
        </div>
      )}
      <Grid
        md={inlineCreateForm ? 4 : 6}
        alignItems="center"
        justify="center"
        style={{ width: '100%' }}
      >
        <Grid container>
          {hasSearchTerm && (
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <OutlinedInput
                fullWidth
                id="search"
                margin="dense"
                placeholder="Pesquisar"
                value={search}
                onChange={onTextChange(fieldChange)}
                onKeyPress={e => e.key === 'Enter' && onSearch()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={() => onSearch()}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {alternativeExport && (
                <Grid item xs={6}>
                  <ExportButtons
                    fullWidth
                    color="tertiary"
                    variant="outlined"
                    isDisabledXlsExport={isDisabledXlsExport}
                    isDisabledPdfExport={isDisabledPdfExport}
                    fileName={fileName}
                    exportType={exportType}
                    exportHeaders={exportHeaders}
                    handlePageRequest={functionAlternativeExport}
                    formatComponentData={formatComponentData}
                  />
                </Grid>
              )}
              <Grid item xs={alternativeExport ? 6 : 12}>
                <Button fullWidth type="button" color="success" onClick={() => onSearch()}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Filter.defaultProps = {
  filters: [],
  exportType: '',
  onPropsTextChange: () => true,
  handleRegistration: () => true,
  handleToRemoveItems: () => {},
  handleToRegisterItem: () => {},
  toggleModal: () => {},
  formatComponentData: () => {},
  hasRemoveButton: false,
  hasExportButton: false,
  inlineCreateForm: false,
  hasLinkButton: '',
  hasModalButton: '',
  linkButtonText: '',
  fileName: '',
  inputText: '',
  itemsToRemove: [],
  exportHeaders: [],
  filter: false,
  hasSearchTerm: false,
  insertTransactionSelect: false
};

Filter.propTypes = {
  handlePageRequest: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      advanced: PropTypes.bool
    })
  ),
  hasRemoveButton: PropTypes.bool,
  hasLinkButton: PropTypes.string,
  hasExportButton: PropTypes.bool,
  hasModalButton: PropTypes.string,
  linkButtonText: PropTypes.string,
  toggleModal: PropTypes.func,
  inlineCreateForm: PropTypes.bool,
  exportType: PropTypes.string,
  itemsToRemove: PropTypes.arrayOf(),
  handleToRemoveItems: PropTypes.func,
  inputText: PropTypes.string,
  onPropsTextChange: PropTypes.func,
  handleToRegisterItem: PropTypes.func,
  formatComponentData: PropTypes.func,
  filter: PropTypes.bool,
  hasSearchTerm: PropTypes.bool,
  fileName: PropTypes.string,
  exportHeaders: PropTypes.arrayOf(PropTypes.shape()),
  insertTransactionSelect: PropTypes.bool,
  handleRegistration: PropTypes.func
};

export default Filter;