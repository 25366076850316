import React from 'react';
import { Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';
import { convertToReal } from 'helpers/converters';
import { formatPhone, formatPostalCode, formatDocument, permanence } from 'helpers/formatters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import Roboto from '../fonts/Roboto-Regular.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';

import { styles } from '../styles';

const ServicesIcon = require('../../../../assets/img/icon_services.png');

Font.register({
  family: 'Roboto',
  src: Roboto
});

Font.register({
  family: 'Roboto-Bold',
  src: RobotoBold
});

const Item = ({ isServices = false, children }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: isServices ? 'center' : 'flex-start',
        padding: '0 10px 0 10px'
      }}
    >
      {children}
    </View>
  );
}

const Header = ({ children }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#FCFCFC',
        border: '1pt solid #D7D7D7',
        borderRadius: 5
      }}
    >
      {children}
    </View>
  );
}

const formatDate = date => {
  const formattedDate = moment(date).format('DD/MM/YYYY - HH:mm');
  return formattedDate;
}

const formatAddress = address => {
  const {
    street,
    number,
    district,
    city,
    state,
    postalCode
  } = address;

  return(
    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
        {number ? `${street}, ${number} - ${district}` : `${street} - ${district}`}
      </Text>
      <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
        {city} - {state} {formatPostalCode(postalCode)}
      </Text>
    </View>
  );
}

const formatContact = (phone, email) => {
  if(phone && email) {
    return `${formatPhone(phone)} / ${email}`;
  }

  if(phone && !email) {
    return formatPhone(phone);
  }

  if(!phone && email) {
    return email;
  }

  return '';
}

const Receipt = ({ exportData }) => {
  const {
    serviceOrderCode,
    plate,
    vehicleModel,
    vehicleColor,
    entryPhotoBase64,
    entryDateTime,
    exitDateTime,
    services,
    typePrice,
    paymentMethodName,
    amountTotal,
    amount,
    discountAmount,
    financialSituationName,
    operationSituationName,
    establishment,
    establishment: { base64Logo }
  } = exportData;

  const isPaid = financialSituationName == 'Pago';
  const isVehicleInside = operationSituationName == 'No pátio';

  const servicesQuantity = services?.reduce((total, service) => total + +service?.quantity, 0);

  const establishmentData = {
    ...establishment?.addresses[0],
    establishmentName: establishment?.establishmentName,
    documentId: establishment?.documentId,
    email: establishment?.email,
    phone: establishment?.phone
  }

  const base64LogoExtension = getImageExtensionFromBase64(base64Logo);
  const base64EntryPhotoExtension = getImageExtensionFromBase64(entryPhotoBase64 || '');

  return(
    <Document>
      <Page style={{ fontFamily: 'Roboto', padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {!!base64Logo && (
              <View style={{ width: !!base64Logo ? '30%' : '0%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <Image
                  style={{
                    objectFit: 'cover',
                    height: 50
                  }}
                  src={`data:image/${base64LogoExtension};base64,${base64Logo}`}
                />
              </View>
            )}
            <View style={{ width: !!base64Logo ? '70%' : '100%' }}>
              <Header>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>{isPaid ? 'Recibo' : 'Comprovante de Entrada'}</Text>
              </Header>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, padding: '0 10px 0 10px' }}>
                <View style={{ width: !!base64Logo ? '30%' : '50%', display: 'flex', justifyContent: 'flex-start' }}>
                  {!!establishmentData?.establishmentName && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Estabelecimento</Text>
                  )}
                  {!!establishmentData?.documentId && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>CNPJ</Text>
                  )}
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Endereço</Text>
                  {(!!establishmentData?.phone || !!establishmentData?.email) && (
                    <Text style={[styles.fontSize11, styles.marginBottom5, { marginTop: 20 }]}>Contato</Text>
                  )}
                </View>
                <View style={{ width: !!base64Logo ? '70%' : '50%', display: 'flex', justifyContent: 'flex-start' }}>
                  {!!establishmentData?.establishmentName && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
                      {establishmentData?.establishmentName}
                    </Text>
                  )}
                  {!!establishmentData?.documentId && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
                      {formatDocument(establishmentData?.documentId)}
                    </Text>
                  )}
                  {formatAddress(establishmentData)}
                  {(!!establishmentData?.phone || !!establishmentData?.email) && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5, { marginTop: 2 }]}>
                      {formatContact(establishmentData?.phone, establishmentData?.email)}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Header>
              <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Dados da ordem de serviço</Text>
              <Text style={[styles.fontSize11, styles.colorDefaultBlack]}>OS: {serviceOrderCode}</Text>
            </Header>
            <View style={{ width: '100%', marginTop: 10 }}>
              <View style={{ width: !!entryPhotoBase64 ? '70%' : '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0 10px 0 10px' }}>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!vehicleModel && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Veículo</Text>
                  )}
                  {!!plate && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Placa</Text>
                  )}
                  {!!entryDateTime && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Entrada</Text>
                  )}
                  {(!!exitDateTime && !isVehicleInside) && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Saída</Text>
                  )}
                  {(!!exitDateTime && !!entryDateTime && !isVehicleInside) && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Permanência</Text>
                  )}
                </View>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!vehicleModel && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{vehicleModel} {vehicleColor}</Text>
                  )}
                  {!!plate && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{plate}</Text>
                  )}
                  {!!entryDateTime && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{formatDate(entryDateTime)}</Text>
                  )}
                  {(!!exitDateTime && !isVehicleInside) && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{formatDate(exitDateTime)}</Text>
                  )}
                  {(!!exitDateTime && !!entryDateTime && !isVehicleInside) && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{permanence([exitDateTime, entryDateTime])}</Text>
                  )}
                </View>
              </View>
              <View style={{ width: !!entryPhotoBase64 ? '30%' : '0%' }}>
                {!!entryPhotoBase64 && (
                  <Image
                    style={{
                      objectFit: 'cover',
                      height: 100
                    }}
                    src={`data:image/${base64EntryPhotoExtension};base64,${entryPhotoBase64}`}
                  />
                )}
              </View>
            </View>
          </View>
          {services.length > 0 && (
            <View style={{ marginTop: 10 }}>
              <Header>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Image
                    style={{ height: 10, width: 10, marginRight: 10 }}
                    src={ServicesIcon}
                  />
                  <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Serviços</Text>
                </View>
                <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold]}>({servicesQuantity}) Serviços</Text>
              </Header>
              <View style={{ marginTop: 10 }}>
                {services.map(service => {
                  return(
                    <Item isServices>
                      <Text style={[styles.fontSize11, styles.marginBottom5]}>({service?.quantity}) {service?.description}</Text>
                      <View style={{ flexGrow: 1, margin: '0 5px 0 5px', borderBottom: '1pt dotted #757575' }} />
                      <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{service?.serviceUsage || convertToReal(service?.amount)}</Text>
                    </Item>
                  );
                })}
              </View>
            </View>
          )}
          {!!typePrice && (
            <View style={{ marginTop: 10 }}>
              <Header>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Tabela de estacionamento</Text>
              </Header>
              <View style={{ marginTop: 10 }}>
                <Item>
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>{typePrice}</Text>
                </Item>
              </View>
            </View>
          )}
          {!!paymentMethodName && (
            <View style={{ marginTop: 10 }}>
              <Header>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Forma de pagamento</Text>
              </Header>
              <View style={{ marginTop: 10 }}>
                <Item>
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>{paymentMethodName}</Text>
                </Item>
              </View>
            </View>
          )}
          <View style={{ marginTop: 10 }}>
            <Header>
              <View>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Valor</Text>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold, { margin: '10px 0 10px 0' }]}>Desconto</Text>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Valor Total</Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Text style={[styles.fontSize15, styles.colorDefaultBlack, styles.fontBold]}>{convertToReal(amount)}</Text>
                <Text style={[styles.fontSize15, styles.colorDefaultBlack, styles.fontBold, { margin: '10px 0 10px 0' }]}>{convertToReal(discountAmount)}</Text>
                <Text style={[styles.fontSize15, styles.colorDefaultBlack, styles.fontBold]}>{convertToReal(amountTotal)}</Text>
              </View>
            </Header>
          </View>
        </View>
        <Text style={[styles.fontSize11, { color: '#757575' }]}>Desenvolvido por Jump Tecnologia</Text>
      </Page>
    </Document>
  );
}

export default Receipt;