import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMinusCircle, faEye, faEyeSlash, faHandshake, faChevronDown, faChevronUp, faLock, faLockOpen, faCog, faEdit } from '@fortawesome/free-solid-svg-icons';

import TableItem from '../TableItem';
import SpecialTable from '../SpecialTable';
import Covenant from '../Covenant';
import ModalMobile from '../ModalMobile';
import ModalConfirmation from '../../../../components/ModalConfirmation';
import Modal from 'components/Modal';
import Button from '../../../../components/Button';

import { handleCovenant, handleUpdate, openSpecialForm } from '../../store/actions';
import { loadCovenant, changeTableProp, removeTable } from '../../store/thunk';
import { convertToReal, convertMinutesToPeriod } from '../../../../helpers/converters';

import { useStyles } from '../styles';

export default function Table({ data }) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const width = window.innerWidth;
  const isMobile = width < 1024;

  const [isOpenModalMobile, setIsOpenModalMobile] = useState(false);
  const [isOpen, openTable] = useState(false);

  const [isSettingTableInsivible, setIsSettingTableInsivible] = useState(false);
  const [isTableInvisible, setIsTableInvisible] = useState(false);

  const [isRemoving, handleRemoveTable] = useState(false);

  const { typePrice, major, items, covenant, invisible, tolerance, maximumPeriod, maximumValue, specialPrices } = data;

  const { isLoading, isSpecial, isCovenant } = useSelector(state => state.priceTable);

  const timeRanges = items
    ?.filter(item => +item?.since == 0)
    ?.sort((a, b) => b.period - a.period)
    ?.reverse();

  const specificTimeRanges = items
    ?.filter(item => +item?.since > 0)
    ?.sort((a, b) => b.since - a.since)
    ?.reverse();

  const handleRemoveConfirmation = () => {
    dispatch(removeTable(typePrice));
  }

  const handleUpdateTable = event => {
    dispatch(openSpecialForm(false, ''));
    dispatch(handleUpdate(data));
    event.preventDefault();
  }

  const handleChangeTableProp = e => {
    const params = {
      invisible: invisible ? 0 : 1
    }

    dispatch(changeTableProp(params, typePrice));
    setIsSettingTableInsivible(false);
  }

  const handleMainTable = () => {
    const params = {
      major: major ? 0 : 1
    }

    dispatch(changeTableProp(params, typePrice));
  }

  const handleCovenantModal = () => {
    dispatch(loadCovenant(typePrice))
      .then(res => {
        if(!res) {
          return dispatch(handleCovenant(true, typePrice, false));
        }

        dispatch(handleCovenant(true, typePrice, true));
      });
  }

  const handleSpecialTableForm = e => {
    const { checked } = e.target;
    dispatch(openSpecialForm(checked, typePrice));
  }

  return (
    <Grid className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Button
            disableElevation
            color="transparent"
            className={classes.title}
            onClick={() => openTable(!isOpen)}
          >
            {typePrice}
          </Button>
        </Grid>
        <Grid item>
          {isMobile ? (
            <>
              <Button
                disableElevation
                color="transparent"
                onClick={handleUpdateTable}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#022A5C"
                />
              </Button>
              <Tooltip
                arrow
                placement="bottom"
                title={covenant ? "O convênio está ativo" : "Ativar convênio"}
              >
                <Button
                  disableElevation
                  color="transparent"
                  onClick={handleCovenantModal}
                >
                  <FontAwesomeIcon
                    icon={faHandshake}
                    color={covenant ? "#FDC02F" : "#C2C2C2"}
                  />
                </Button>
              </Tooltip>
              <Button
                disableElevation
                color="transparent"
                onClick={() => setIsOpenModalMobile(true)}
              >
                <FontAwesomeIcon
                  icon={faCog}
                  color="#022A5C"
                />
              </Button>
            </>
          ) : (
            <>
              <Tooltip arrow placement="bottom" title="Editar">
                <Button
                  disableElevation
                  color="transparent"
                  onClick={event => handleUpdateTable(event)}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    color="#022A5C"
                  />
                </Button>
              </Tooltip>
              <Tooltip arrow placement="bottom" title="Remover">
                <Button
                  disableElevation
                  color="transparent"
                  onClick={() => handleRemoveTable(true)}
                >
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    color="#F66D6E"
                  />
                </Button>
              </Tooltip>
              <Tooltip arrow placement="bottom" title="Torna principal">
                <Button
                  disableElevation
                  id="major"
                  color="transparent"
                  onClick={handleMainTable}
                >
                  <FontAwesomeIcon
                    icon={major ? faLock : faLockOpen}
                    color="#52BC76"
                  />
                </Button>
              </Tooltip>
              <Tooltip
                arrow
                placement="bottom"
                title={covenant ? "O convênio está ativo" : "Ativar convênio"}
              >
                <Button
                  disableElevation
                  color="transparent"
                  onClick={handleCovenantModal}
                >
                  <FontAwesomeIcon
                    icon={faHandshake}
                    color={covenant ? "#FDC02F" : "#C2C2C2"}
                  />
                </Button>
              </Tooltip>
              <Tooltip
                arrow
                placement="bottom"
                title={invisible ? 'Tabela está invisível' : 'Tabela está visível'}
              >
                <Button
                  disableElevation
                  id="invisible"
                  color="transparent"
                  onClick={() => invisible ? handleChangeTableProp() : setIsSettingTableInsivible(true)}
                >
                  <FontAwesomeIcon
                    icon={invisible ? faEyeSlash : faEye}
                    color="#022A5C"
                  />
                </Button>
              </Tooltip>
            </>
          )}
          <Button
            disableElevation
            color="transparent"
            onClick={() => openTable(!isOpen)}
          >
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              color="#757575"
            />
          </Button>
        </Grid>
      </Grid>
      {isSettingTableInsivible && (
        <Modal
          title='Tabela invisível'
          open={isSettingTableInsivible}
          onClose={() => setIsSettingTableInsivible(false)}
        >
          <Grid container>
            <Grid xs={12} style={{ margin: '10px 0 10px 0' }}>
              Ao tornar a tabela invisível, ela não poderá ser selecionada no aplicativo operacinal.
            </Grid>
            <Grid xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='prePaidExit'
                    type='checkbox'
                    checked={isTableInvisible}
                    onChange={e => setIsTableInvisible(e.target.checked)}
                  />
                }
                label='Deixar tabela invisível'
              />
            </Grid>
            <Grid xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type='submit'
                color='success'
                disabled={!isTableInvisible}
                onClick={handleChangeTableProp}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
      <ModalConfirmation
        open={isRemoving}
        isLoading={isLoading}
        onClose={() => handleRemoveTable(false)}
        handleConfirmation={handleRemoveConfirmation}
      >
        Não remova se tiver com alguma Ordem de serviço no pátio. Haverá problema de cobrança. Troque a tabela no aplicativo antes de remover.
      </ModalConfirmation>
      <Covenant isOpen={isCovenant} />
      {isMobile && (
        <ModalMobile
          covenant={covenant}
          typePrice={typePrice}
          handleChangeTableProp={handleChangeTableProp}
          handleInvisible={handleChangeTableProp}
          handleMainTable={handleMainTable}
          invisible={invisible ? 'Tabela está invisível' : 'Tabela está visível'}
          removeTable={handleRemoveTable}
          setIsOpenModalMobile={setIsOpenModalMobile}
          isOpen={isOpenModalMobile}
        />
      )}
      <Collapse in={isOpen}>
        <Grid container>
          {tolerance !== 0 && (
            <Grid xs={12}>
              <TableItem
                value={`Tolerancia inicial de ${convertMinutesToPeriod(
                  tolerance
                )}`}
              />
            </Grid>
          )}
          {timeRanges?.length > 0 && (
            timeRanges?.map((timeRange, index) => {
              return(
                <Grid key={index} xs={12}>
                  <TableItem
                    value={`Até ${convertMinutesToPeriod(
                      timeRange.period
                    )} cobrar o valor de ${convertToReal(timeRange.price)}`}
                  />
                </Grid>
              )
            })
          )}
          {specificTimeRanges?.length > 0 && (
            specificTimeRanges?.map((timeRange, index) => {
              return(
                <Grid key={index} xs={12}>
                  <TableItem
                    value={`A partir de ${convertMinutesToPeriod(
                      timeRange.since
                    )} a cada ${convertMinutesToPeriod(
                      timeRange.period
                    )} somar ${convertToReal(timeRange.price)}`}
                  />
                </Grid>
              )
            })
          )}
          {maximumPeriod !== 0 && (
            <Grid xs={12}>
              <TableItem
                value={`Até o período de ${convertMinutesToPeriod(
                  maximumPeriod
                )} cobrar o valor máximo de ${convertToReal(maximumValue)}`}
              />
            </Grid>
          )}
        </Grid>
        {specialPrices && specialPrices.map(specialPrice => (
          <SpecialTable data={specialPrice} />
        ))}
        <Grid container justify="flex-end">
          <FormControlLabel
            value="start"
            labelPlacement="start"
            label="Deseja adicionar uma condição especial a essa tabela?"
            className={classes.title}
            control={
              <Switch
                color="primary"
                onChange={handleSpecialTableForm}
                checked={isSpecial.includes(typePrice)}
              />
            }
          />
        </Grid>
      </Collapse>
    </Grid>
  );
}

Table.propTypes = {
  data: PropTypes.shape({
    typePrice: PropTypes.string,
    major: PropTypes.bool,
    covenant: PropTypes.bool,
    invisible: PropTypes.bool,
    tolerance: PropTypes.number,
    items: PropTypes.string,
    maximumPeriod: PropTypes.string,
    maximumValue: PropTypes.string,
    specialPrices: PropTypes.arrayOf(PropTypes.shape())
  })
};

Table.defaultProps = {
  data: {
    typePrice: '',
    major: false,
    covenant: false,
    invisible: false,
    tolerance: '',
    items: [],
    maximumPeriod: '',
    maximumValue: '',
    specialPrices: []
  }
};