import React from 'react';

import { Typography } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function WarningMessage({ message, iconColor, fontColor }) {
  const getIconColor = () => {
    if(iconColor == 'error') {
      return '#E53935';
    }

    return '#F5B941';
  }

  return(
    <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color={getIconColor()}
        size="lg"
      />
      <Typography color={fontColor ? fontColor : 'textSecondary'}>
        {message}
      </Typography>
    </div>
  );
}