import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numericFormatter } from 'react-number-format';

import { Paper, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Loader from 'components/Loader';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import MTable from 'components/MTable';
import SellOrder from 'components/SellOrder/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { loadFilters } from 'components/Filter/store/thunk';
import { loadSellOrdersList, loadSellOrdersResume, loadSellOrdersExport, loadSellOrder } from '../store/thunk';

import { nameInput, documentInput, orderInput, productInput } from './resources';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Código', field: 'orderCode' },
  { title: 'Criação', field: 'registrationDateTime' },
  { title: 'Cliente', field: 'client' },
  { title: 'Meio de Pagamento', field: 'paymentMethodName' },
  { title: 'Valor Total', field: 'totalAmount' },
  { title: 'Situação', field: 'situationDescription' }
];

export default function SellOrders() {
  const dispatch = useDispatch();

  const didComponentMount = useDidMount();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp, selects } = useSelector(state => state.filters);
  const { isLoading, total, list, resume } = useSelector(state => state.sellOrders);

  const [isSellOrderModalOpen, setIsSellOrderModalOpen] = useState(false);

  const [sellOrdersPage, setSellOrdersPage] = useState(1);
  const sellOrdersPageSize = 10;
  const sellOrdersPageCount = Math.ceil(total / sellOrdersPageSize);

  const handleLoadSellOrdersExport = () => {
    const extraProps = {
      startDate,
      endDate,
      qp
    }

    return dispatch(loadSellOrdersExport(userId, establishmentId, extraProps));
  }

  const handleLoadSellOrdersList = ({ page, qp }) => {
    const extraProps = {
      startDate,
      endDate,
      qp,
      page,
      limit: 10
    }

    dispatch(loadSellOrdersList(userId, establishmentId, extraProps));
  }

  const handleLoadSellOrdersResume = qp => {
    const extraProps = {
      startDate,
      endDate,
      qp
    }

    dispatch(loadSellOrdersResume(userId, establishmentId, extraProps));
  }

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadFilters(userId, establishmentId));
      handleLoadSellOrdersResume(qp);
      handleLoadSellOrdersList({ page: 1, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount) {
      handleLoadSellOrdersResume(qp);
      handleLoadSellOrdersList({ page: sellOrdersPage, qp });
    }
  }, [sellOrdersPage]);

  const formattedFilters = [
    nameInput,
    documentInput,
    {
      advanced: false,
      type: 'select',
      id: 'situationId',
      label: 'Situação',
      value: 0,
      options: selects
        ?.find(select => select?.id == 'orderSituation')?.options
        ?.map(option => {
          return {
            value: option?.id,
            label: option?.label
          }
        })
    },
    {
      advanced: false,
      type: 'select',
      id: 'registrationUserId',
      label: 'Usuário',
      value: 0,
      options: selects?.find(select => select?.id == 'user')?.options || []
    },
    orderInput,
    productInput,
    {
      advanced: false,
      type: 'select',
      id: 'paymentMethodId',
      label: 'Formas de Pagamento',
      value: 0,
      options: selects?.find(select => select?.id == 'paymentMethod')?.options || []
    },
  ];

  return(
    <>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0' }}>
            <Loader isLoading={isLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Total de faturamento
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                R$ {numericFormatter(resume?.totalAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
              </Typography>
            </Loader>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper style={{ borderBottom: '5px solid #13BC4B', padding: '10px 0 10px 0' }}>
            <Loader isLoading={isLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Pedidos pagos
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {resume?.countPaid}
              </Typography>
            </Loader>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper style={{ borderBottom: '5px solid #757575', padding: '10px 0 10px 0' }}>
            <Loader isLoading={isLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Total de pedidos
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                {resume?.count}
              </Typography>
            </Loader>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper style={{ borderBottom: '5px solid #757575', padding: '10px 0 10px 0' }}>
            <Loader isLoading={isLoading}>
              <Typography variant="subtitle1" color="primary" align="center">
                Ticket médio
              </Typography>
              <Typography variant="h4" color="primary" align="center">
                R$ {numericFormatter(resume?.mediumAmount?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })}
              </Typography>
            </Loader>
          </Paper>
        </Grid>
      </Grid>
      <Paper>
        {isSellOrderModalOpen && (
          <SellOrder
            isSellOrderModalOpen={isSellOrderModalOpen}
            setIsSellOrderModalOpen={setIsSellOrderModalOpen}
            sellOrdersPageSize={sellOrdersPageSize}
            sellOrdersPage={sellOrdersPage}
            pageOrigin='/historico-de-venda'
          />
        )}
        {isMobile ? (
          <FilterMobile
          filter
          filters={formattedFilters}
          isDisabledPdfExport
          alternativeExport
          functionAlternativeExport={handleLoadSellOrdersExport}
          exportType='sellOrders'
          fileName='Histórico de Venda'
          handlePageRequest={({ page, qp }) => {
            handleLoadSellOrdersResume(qp);
            handleLoadSellOrdersList({ page, qp });
          }}
          />
        ) : (
          <Filter
            filter
            filters={formattedFilters}
            isDisabledPdfExport
            alternativeExport
            functionAlternativeExport={handleLoadSellOrdersExport}
            exportType='sellOrders'
            fileName='Histórico de Venda'
            handlePageRequest={({ page, qp }) => {
              handleLoadSellOrdersResume(qp);
              handleLoadSellOrdersList({ page, qp });
            }}
          />
        )}
        <MTable
          loading={isLoading}
          headers={headers}
          data={list}
          lineBreak
          actions={[
            rowData => ({
              icon: () => (
                <FontAwesomeIcon
                  icon={faEye}
                  color="#022A5C"
                  size="xs"
                />
              ),
              tooltip: 'Visualizar ordem de venda',
              onClick: () => {
                setIsSellOrderModalOpen(true);
                dispatch(loadSellOrder(userId, establishmentId, rowData?.orderId));
              }
            })
          ]}
        />
        <Grid
          container
          xs={12}
          style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
        >
          <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            count={sellOrdersPageCount}
            page={sellOrdersPage}
            onChange={(_, page) => setSellOrdersPage(page)}
          />
        </Grid>
      </Paper>
    </>
  );
}