import { toast } from 'react-toastify';

import {
  fetchStorageToSellHistoryResume,
  fetchStorageHistoryResume,
  fetchStorageHistoryList,
  updateIncreaseStorage,
  updateDecraseStorage,
  updateAccuseEmptyStorage,
  updateAccuseExceedingStorage,
  updateMigrateStock
} from './services';

import {
  setStorageHistoryResumeLoading,
  setStorageHistoryResume,
  setStorageSellHistoryResume,
  setStorageHistoryListLoading,
  setStorageHistoryList,
  setStorageHistoryModalLoading,
  setStorageHistoryErrors,
  clearStorageHistoryErrors
} from './actions';

export const loadStorageToSellHistoryResume = (userId, establishmentId, storageProductUuid) => dispatch => {
  dispatch(setStorageHistoryResumeLoading(true));

  return fetchStorageToSellHistoryResume(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setStorageSellHistoryResume(data)))
    .finally(() => dispatch(setStorageHistoryResumeLoading(false)));
}

export const loadStorageHistoryResume = (userId, establishmentId, storageProductUuid) => dispatch => {
  dispatch(setStorageHistoryResumeLoading(true));

  return fetchStorageHistoryResume(userId, establishmentId, storageProductUuid)
    .then(({ data }) => dispatch(setStorageHistoryResume(data)))
    .finally(() => dispatch(setStorageHistoryResumeLoading(false)));
}

export const loadStorageHistoryList = (userId, establishmentId, storageProductUuid, page) => dispatch => {
  dispatch(setStorageHistoryListLoading(true));

  return fetchStorageHistoryList(userId, establishmentId, storageProductUuid, page)
    .then(response => {
      const { data, total } = response?.data?.storageHistory;
      dispatch(setStorageHistoryList({ list: data, total }));
    })
    .finally(() => dispatch(setStorageHistoryListLoading(false)));
}

export const increaseStorage = (userId, establishmentId, storageProductUuid, productType, pageOrigin, isStorageMovementSell, params) => dispatch => {
  dispatch(setStorageHistoryModalLoading(true));

  return updateIncreaseStorage(userId, establishmentId, storageProductUuid, isStorageMovementSell, params)
    .then(({ data }) => {
      if(pageOrigin == '/movimentacao-de-estoque') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, 1));

        if(productType == 'consumo') {
          dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
          return;
        }

        if(productType == 'venda') {
          dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
        }
      }

      toast.success('Sucesso ao adicionar ao estoque.');
      return Promise.resolve(data);
    })
    .catch(error => {
      dispatch(clearStorageHistoryErrors());

      if(+error?.httpCode == 422) {
        dispatch(setStorageHistoryErrors(error?.errors));
      }

      toast.error('Houve um erro ao adicionar ao estoque.');
      return Promise.reject();
    })
    .finally(() => dispatch(setStorageHistoryModalLoading(false)));
}

export const decreaseStorage = (userId, establishmentId, storageProductUuid, productType, pageOrigin, isStorageMovementSell, params) => dispatch => {
  dispatch(setStorageHistoryModalLoading(true));

  return updateDecraseStorage(userId, establishmentId, storageProductUuid, isStorageMovementSell, params)
    .then(({ data }) => {
      if(pageOrigin == '/movimentacao-de-estoque') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, 1));

        if(productType == 'consumo') {
          dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
        }

        if(productType == 'venda') {
          dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
        }
      }

      toast.success('Sucesso ao remover do estoque.');
      return Promise.resolve(data);
    })
    .catch(error => {
      dispatch(clearStorageHistoryErrors());

      if(+error?.httpCode == 422) {
        dispatch(setStorageHistoryErrors(error?.errors));
      }

      toast.error('Houve um erro ao remover do estoque.');
      return Promise.reject();
    })
    .finally(() => dispatch(setStorageHistoryModalLoading(false)));
}

export const accuseExceedingStorage = (userId, establishmentId, storageProductUuid, productType, pageOrigin, isStorageMovementSell, params) => dispatch => {
  dispatch(setStorageHistoryModalLoading(true));

  return updateAccuseExceedingStorage(userId, establishmentId, storageProductUuid, isStorageMovementSell, params)
    .then(({ data }) => {
      if(pageOrigin == '/movimentacao-de-estoque') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, 1));

        if(productType == 'consumo') {
          dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
        }

        if(productType == 'venda') {
          dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
        }
      }

      toast.success('Sucesso ao acusar estoque excedente.');
      return Promise.resolve(data);
    })
    .catch(error => {
      dispatch(clearStorageHistoryErrors());

      if(+error?.httpCode == 422) {
        dispatch(setStorageHistoryErrors(error?.errors));
      }

      return { error: true }
    })
    .finally(() => dispatch(setStorageHistoryModalLoading(false)));
}

export const accuseEmptyStorage = (userId, establishmentId, storageProductUuid, productType, pageOrigin, isStorageMovementSell, params) => dispatch => {
  dispatch(setStorageHistoryModalLoading(true));

  return updateAccuseEmptyStorage(userId, establishmentId, storageProductUuid, isStorageMovementSell, params)
    .then(({ data }) => {
      if(pageOrigin == '/movimentacao-de-estoque') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, 1));

        if(productType == 'consumo') {
          dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
        }

        if(productType == 'venda') {
          dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
        }
      }

      toast.success('Sucesso ao acusar estoque zerado.');
      return Promise.resolve(data);
    })
    .catch(() => {
      toast.error('Houve um erro ao acusar estoque zerado.');
      return Promise.reject();
    })
    .finally(() => dispatch(setStorageHistoryModalLoading(false)));
}

export const migrateStock = (userId, establishmentId, storageProductUuid, productType, pageOrigin, params) => dispatch => {
  dispatch(setStorageHistoryModalLoading(true));

  return updateMigrateStock(userId, establishmentId, storageProductUuid, params)
    .then(({ data }) => {
      if(pageOrigin == '/movimentacao-de-estoque') {
        dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, 1));

        if(productType == 'consumo') {
          dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
        }

        if(productType == 'venda') {
          dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
        }
      }

      toast.success('Estoque migrado com sucesso.');
      return Promise.resolve(data);
    })
    .catch(() => {
      toast.error('Houve um erro ao migrar o estoque.');
      return Promise.reject();
    })
    .finally(() => dispatch(setStorageHistoryModalLoading(false)));
}