import * as type from './constants';

export const setProvisionalReceipt = (data, total) => ({
  type: type.SET_RPS,
  data,
  total
});

export const setRpsInfo = data => ({
  type: type.SET_RPS_INFO,
  data
});

export const setIsNfInfo = status => ({
  type: type.SET_IS_NF_INFO,
  status
});

export const setSourceId = sourceId => ({
  type: type.SET_SOURCE_ID,
  sourceId
});

export const setSelectedNfWithErrors = data => ({
  type: type.SET_SELECTED_NF_WITH_ERRORS,
  data
});

export const setSelectedNfWithErrorsPaymentDateTime = data => ({
  type: type.SET_SELECTED_SINGLE_NF_WITH_ERRORS_PAYMENT_DATE_TIME,
  data
});

export const setSelectedSingleNfWithErrors = sourceId => ({
  type: type.SET_SELECTED_SINGLE_NF_WITH_ERRORS,
  sourceId
});

export const clearPageNfWithErrors = data => ({
  type: type.CLEAR_SELECTED_PAGE_NF_WITH_ERRORS,
  data
});

export const clearNfWithErrors = () => ({
  type: type.CLEAR_SELECTED_NF_WITH_ERRORS
});

export const setIsNfSendModalOpen = status => ({
  type: type.SET_IS_NF_SEND_MODAL_OPEN,
  status
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});

export const handleError = ({ error, transactionId, establishmentId, paymentDateTime }) => ({
  type: type.HANDLE_ERROR,
  error,
  transactionId,
  establishmentId,
  paymentDateTime
});