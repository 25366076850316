import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Paper, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from 'components/MTable';
import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import Button from 'components/Button';
import PayrollSheet from 'components/PayrollSheet';
import Comissions from '../../Comissions/components';
import ComissionedPayrollRegistration from '../../ComissionedPayrollRegistration/components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCog, faPen, faFileAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

import { filterInputs } from './resources';

import { setPayrollSheetEmployeeUuid, setCommissionedUuid, setPayrollSheet, clearPayrollSheet } from '../../ComissionedPayrollSheet/store/actions';
import { loadComissionedFilters } from 'pages/ComissionedList/store/thunk';
import { loadComissionedPayrollListSettings } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

const headers = [
  { title: 'Nome', field: 'name', sortable: false },
  { title: 'Perfil', field: 'profile', sortable: false }
];

export default function ComissionedPayrollList() {
  const dispatch = useDispatch();

  const history = useHistory();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { qp } = useSelector(state => state.filters);
  const { commissionProfiles } = useSelector(state => state.comissionedList.filters);
  const { list, total, isLoading } = useSelector(state => state.comissionedPayrollList);

  const [comissionedUuid, setComissionedUuid] = useState('');
  const [isComissionedPayrollRegistrationDrawerOpen, setIsComissionedPayrollRegistrationDrawerOpen] = useState(false);
  const [isComissionsConfigDrawerOpen, setIsComissionsConfigDrawerOpen] = useState(false);
  const [isPayrollSheetModalOpen, setIsPayrollSheetModalOpen] = useState(false);

  const [comissionedPayrollListPage, setComissionedPayrollListPage] = useState(1);
  const comissionedPayrollListPageSize = 10;
  const comissionedPayrollListCount = Math.ceil(total / comissionedPayrollListPageSize);

  const requestComissionedPayrollListSettings = ({ page, qp }) => {
    if(userId) {
      dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page, limit: 10, qp }));
    }
  }

  useEffect(() => {
    if(userId && establishmentId) {
      requestComissionedPayrollListSettings({ page: 1, qp });
    }
  }, [userId, establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    dispatch(loadComissionedFilters({ userId }));
  }, []);

  const formattedCommissionProfiles = commissionProfiles?.options?.map(profile => {
    return {
      value: profile?.id,
      label: profile?.name
    }
  });

  const commissionProfilesFilter = {
    advanced: false,
    type: 'select',
    id: 'profileId',
    options: formattedCommissionProfiles,
    label: 'Perfil',
    placeholder: 'Perfil',
    value: ''
  }

  const openCurrentPayrollSheet = commissioned => {
    const { name, profileId, uuid } = commissioned;

    dispatch(clearPayrollSheet());
    dispatch(setCommissionedUuid(uuid));
    dispatch(setPayrollSheet({ name, profileId }));

    setIsPayrollSheetModalOpen(true);
  }

  return(
    <Paper>
      {isComissionsConfigDrawerOpen && (
        <Comissions
          open={isComissionsConfigDrawerOpen}
          onClose={() => setIsComissionsConfigDrawerOpen(false)}
        />
      )}
      {isComissionedPayrollRegistrationDrawerOpen && (
        <ComissionedPayrollRegistration
          uuid={comissionedUuid}
          open={isComissionedPayrollRegistrationDrawerOpen}
          onClose={() => setIsComissionedPayrollRegistrationDrawerOpen(false)}
          page={comissionedPayrollListPage}
          limit={comissionedPayrollListPageSize}
          qp={qp}
        />
      )}
      {isPayrollSheetModalOpen && (
        <PayrollSheet
          isPayrollSheetModalOpen={isPayrollSheetModalOpen}
          setIsPayrollSheetModalOpen={setIsPayrollSheetModalOpen}
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          actionButton={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={() => {
                  setComissionedUuid('');
                  setIsComissionedPayrollRegistrationDrawerOpen(true);
                }}
              >
                Adicionar funcionário
              </Button>
              <Button
                type="button"
                color="tertiary"
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon
                    icon={faCog}
                    color="#757575"
                    size="xs"
                  />
                }
                onClick={() => setIsComissionsConfigDrawerOpen(true)}
              >
                Configurações
              </Button>
            </div>
          }
          handlePageRequest={({ qp }) => {
            setComissionedPayrollListPage(1);
            return requestComissionedPayrollListSettings({ page: 1, qp });
          }}
          filters={filterInputs.concat(commissionProfilesFilter)}
        />
      ) : (
        <Filter
          filter
          actionButton={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
              <Button
                type="button"
                color="primary"
                startIcon={
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color="#FFFFFF"
                    size="xs"
                  />
                }
                onClick={() => {
                  setComissionedUuid('');
                  setIsComissionedPayrollRegistrationDrawerOpen(true);
                }}
              >
                Adicionar comissionado
              </Button>
              <Button
                type="button"
                color="tertiary"
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon
                    icon={faCog}
                    color="#757575"
                    size="xs"
                  />
                }
                onClick={() => setIsComissionsConfigDrawerOpen(true)}
              >
                Configurações
              </Button>
            </div>
          }
          handlePageRequest={({ qp }) => {
            setComissionedPayrollListPage(1);
            return requestComissionedPayrollListSettings({ page: 1, qp });
          }}
          filters={filterInputs.concat(commissionProfilesFilter)}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={list?.map(commissioned => {
          return {
            ...commissioned,
            profile: commissionProfiles?.options?.find(commissionProfile => commissionProfile?.id == commissioned?.profileId)?.name
          }
        })}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Folha de comissão pendente',
            onClick: (_, rowData) => openCurrentPayrollSheet(rowData)
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faFileAlt}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Folhas de comissão',
            onClick: (_, rowData) => {
              const { employeeUuid } = rowData;

              dispatch(setPayrollSheetEmployeeUuid(employeeUuid));

              history.push({
                pathname: '/folhas-de-comissao',
                state: {
                  data: rowData
                }
              });
            }
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color='#022A5C'
                size='xs'
              />
            ),
            tooltip: 'Editar comissinado',
            onClick: (_, rowData) => {
              setComissionedUuid(rowData?.uuid);
              setIsComissionedPayrollRegistrationDrawerOpen(true);
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color='primary'
          variant='outlined'
          shape='rounded'
          count={comissionedPayrollListCount}
          page={comissionedPayrollListPage}
          onChange={(_, page) => setComissionedPayrollListPage(page)}
        />
      </Grid>
    </Paper>
  );
}