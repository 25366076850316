import React from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { formatPhone, formatPostalCode, formatDocument } from 'helpers/formatters';
import { convertToReal } from 'helpers/converters';
import { getImageExtensionFromBase64 } from 'helpers/common';

import { styles } from '../styles';

const Header = ({ children }) => {
  return(
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        backgroundColor: '#FCFCFC',
        border: '1pt solid #D7D7D7',
        borderRadius: 5
      }}
    >
      {children}
    </View>
  );
}

const formatAddress = address => {
  const {
    street,
    number,
    district,
    city,
    state,
    postalCode
  } = address;

  return(
    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
        {number ? `${street}, ${number} - ${district}` : `${street} - ${district}`}
      </Text>
      <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
        {city} - {state} {formatPostalCode(postalCode)}
      </Text>
    </View>
  );
}

const formatContact = (phone, email) => {
  if(phone && email) {
    return `${formatPhone(phone)} / ${email}`;
  }

  if(phone && !email) {
    return formatPhone(phone);
  }

  if(!phone && email) {
    return email;
  }

  return '';
}

const getInvoiceSituationChip = isPaid => {
  if(isPaid) {
    return(
      <View style={{ backgroundColor: '#4CAF50', width: 65, borderRadius: 25 }}>
        <View style={{ backgroundColor: '#FFFFFF', opacity: 0.8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text style={[styles.fontSize11, { color: '#4CAF50', padding: 5 }]}>Paga</Text>
        </View>
      </View>
    );
  }

  return(
    <View style={{ backgroundColor: '#FF9800', width: 65, borderRadius: 25 }}>
      <View style={{ backgroundColor: '#FFFFFF', opacity: 0.8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Text style={[styles.fontSize11, { color: '#FF9800', padding: 5 }]}>Pendente</Text>
      </View>
    </View>
  );
}

const ReceiptInvoice = ({ exportData }) => {
  const {
    name,
    email,
    phone,
    documentId
  } = exportData.client;

  const establishmentData = {
    ...exportData.establishment,
    ...exportData.establishment.addresses[0]
  }

  const {
    dueDateTime,
    paymentDateTime,
    paymentMethodId,
    amountReceived,
    situationId
  } = exportData.invoice;

  const isPaid = +situationId == 2;

  const paymentMethodName =
    exportData?.paymentMethods?.find(paymentMethod => paymentMethod.value == paymentMethodId)?.label;

  const base64Extension = getImageExtensionFromBase64(establishmentData?.base64Logo);

  return(
    <Document>
      <Page style={{ fontFamily: 'Roboto', padding: 10, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <View>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {!!establishmentData?.base64Logo && (
              <View style={{ width: !!establishmentData?.base64Logo ? '30%' : '0%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <Image
                  style={{
                    objectFit: 'cover',
                    height: 50
                  }}
                  src={`data:image/${base64Extension};base64,${establishmentData?.base64Logo}`}
                />
              </View>
            )}
            <View style={{ width: !!establishmentData?.base64Logo ? '70%' : '100%' }}>
              <Header>
                <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Fatura</Text>
              </Header>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, padding: '0 10px 0 10px' }}>
                <View style={{ width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
                  {!!establishmentData?.establishmentName && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Estabelecimento</Text>
                  )}
                  {!!establishmentData?.documentId && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>CNPJ</Text>
                  )}
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Endereço</Text>
                  {(!!establishmentData?.phone || !!establishmentData?.email) && (
                    <Text style={[styles.fontSize11, styles.marginBottom5, { marginTop: 20 }]}>Contato</Text>
                  )}
                </View>
                <View style={{ width: '70%', display: 'flex', justifyContent: 'flex-start' }}>
                  {!!establishmentData?.establishmentName && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
                      {establishmentData?.establishmentName}
                    </Text>
                  )}
                  {!!establishmentData?.documentId && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>
                      {formatDocument(establishmentData?.documentId)}
                    </Text>
                  )}
                  {formatAddress(establishmentData)}
                  {(!!establishmentData?.phone || !!establishmentData?.email) && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5, { marginTop: 2 }]}>
                      {formatContact(establishmentData?.phone, establishmentData?.email)}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Header>
              <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Informações do cliente</Text>
            </Header>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, padding: '0 10px 0 10px' }}>
              <View style={{ width: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!name && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Cliente</Text>
                  )}
                  {!!email && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>E-mail</Text>
                  )}
                </View>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!name && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{name}</Text>
                  )}
                  {!!email && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{email}</Text>
                  )}
                </View>
              </View>
              <View style={{ width: '10%' }} />
              <View style={{ width: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!documentId && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>CPF/CNPJ</Text>
                  )}
                  {!!phone && (
                    <Text style={[styles.fontSize11, styles.marginBottom5]}>Telefone</Text>
                  )}
                </View>
                <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  {!!documentId && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{formatDocument(documentId)}</Text>
                  )}
                  {!!phone && (
                    <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{phone}</Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Header>
              <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Informações da fatura</Text>
            </Header>
            <View style={{ width: '45%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, padding: '0 10px 0 10px' }}>
              <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {!!paymentMethodName && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Meio de pagamento</Text>
                )}
                {!!dueDateTime && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Data de vencimento</Text>
                )}
                {(isPaid && !!paymentDateTime) && (
                  <Text style={[styles.fontSize11, styles.marginBottom5]}>Data de pagamento</Text>
                )}
                <Text style={[styles.fontSize11, styles.marginBottom5]}>Situação</Text>
              </View>
              <View style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {!!paymentMethodName && (
                  <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{paymentMethodName}</Text>
                )}
                {!!dueDateTime && (
                  <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{moment(dueDateTime, 'YY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Text>
                )}
                {(isPaid && !!paymentDateTime) && (
                  <Text style={[styles.fontSize11, styles.colorDefaultBlack, styles.fontBold, styles.marginBottom5]}>{moment(paymentDateTime, 'YY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}</Text>
                )}
                {getInvoiceSituationChip(isPaid)}
              </View>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Header>
              <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>Valor total</Text>
              <Text style={[styles.fontSize13, styles.colorDefaultBlack, styles.fontBold]}>{convertToReal(amountReceived)}</Text>
            </Header>
          </View>
        </View>
        <Text style={[styles.fontSize11, { color: '#757575' }]}>Desenvolvido por Jump Tecnologia</Text>
      </Page>
    </Document>
  );
}

export default ReceiptInvoice;