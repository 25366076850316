import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';
import { map, range } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import Input from '../../../../../../components/Input';
import Loader from '../../../../../../components/Loader';
import Select from '../../../../../../components/Select';
import SingleDatePicker from '../../../../../../components/SingleDatePicker';

import { convertToOptions, convertDateToDB } from '../../../../../../helpers/converters';
import { loadCharges } from '../store/thunk';

import { useStyles } from './styles';

const monthDays = map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(1, 32));

export default function Charges({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId, isEdit } = useSelector(state => state.clientRegistration.basicInfo);
  const clientTypeId = useSelector(state => state.clientList.clientTypeId);
  const { isLoading, createdInvoiceDate, submit: { contractDueDate } } = useSelector(state => state.clientRegistration.charges);
  const filters = useSelector(state => state.filters.selects);

  const availableFrequencies =
    convertToOptions(['frequency'], filters)
    .filter(value => value.label !== '--');

  const [isInvoiceDateVisible, setIsInvoiceDateVisible] = useState(true);

  const frequencyIdToOptions = frequencyId => {
    if(+frequencyId == 1) {
      return map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(0, 30));
    }

    if(+frequencyId == 3) {
      return map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(0, 7));
    }

    if(+frequencyId == 4) {
      return map(day => ({ id: 'invoiceDueDay', label: day, value: day }), range(0, 15));
    }

    return 0;
  }

  const getClosingDaysValue = closingDays => {
    if(closingDays == null) {
      return 0;
    }

    return closingDays;
  }

  const firstInvoiceDueDate = formikInjectProps.values.invoiceDate;
  const firstInvoiceClosingDate = moment(firstInvoiceDueDate).subtract(formikInjectProps.values.closingDays || 0, 'days');
  const firstInvoiceClosingDateLessOneDay = moment(firstInvoiceClosingDate).subtract(1, 'days');
  const firstInvoiceDueDateWithInvoiceDueDay = moment(firstInvoiceDueDate).set('date', formikInjectProps.values.invoiceDueDay || moment.now());

  const secondInvoiceDueDate = firstInvoiceDueDateWithInvoiceDueDay.add(1, 'M');
  const secondInvoiceClosingDate = moment(secondInvoiceDueDate).subtract(1, 'days');
  const secondInvoiceClosingDateLessOneDay = moment(secondInvoiceClosingDate).subtract(1, 'days');

  useEffect(() => {
    formikInjectProps.setFieldValue('closingDays', 0);
  }, [formikInjectProps.values.frequencyId]);

  useEffect(() => {
    (async () => {
      const params = {
        userId,
        establishmentId,
        clientId
      }

      const {
        invoiceDate,
        invoiceDueDay,
        contractDueDate,
        toleranceDays,
        penalty,
        penaltyType,
        closingDays,
        amount,
        frequencyId
      } = await dispatch(loadCharges(params));

      setIsInvoiceDateVisible(frequencyId == 1 && invoiceDueDay == 0);

      const convertedAmount = VMasker.toMoney(amount || 0);

      formikInjectProps.setFieldValue('invoiceDate', invoiceDate);
      formikInjectProps.setFieldValue('invoiceDueDay', invoiceDueDay);
      formikInjectProps.setFieldValue('contractDueDate', createdInvoiceDate ? contractDueDate : null);
      formikInjectProps.setFieldValue('toleranceDays', toleranceDays);
      formikInjectProps.setFieldValue('penalty', penalty);
      formikInjectProps.setFieldValue('penaltyType', penaltyType);
      formikInjectProps.setFieldValue('closingDays', closingDays);
      formikInjectProps.setFieldValue('amount', convertedAmount);
      formikInjectProps.setFieldValue('frequencyId', frequencyId);
    })();
  }, []);

  const handleFrequencySelection = ({ target: { value } }, setFieldValue) => {
    if(value !== 1) {
      setFieldValue('invoiceDueDay', '');
      formikInjectProps.setFieldValue('invoiceDueDay', '');
    }

    setFieldValue('frequencyId', value);
    formikInjectProps.setFieldValue('frequencyId', value);
  }

  const handlePenalty = penalty => {
    const convertedPenalty = VMasker.toMoney(penalty).replaceAll('.', '').replace(',', '.');
    formikInjectProps.setFieldValue('penalty', convertedPenalty);
  }

  const handleAmount = amount => {
    const convertedAmount = VMasker.toMoney(amount);
    formikInjectProps.setFieldValue('amount', convertedAmount);
  }

  const handleContractDueDate = date => {
    const convertedDate = convertDateToDB(date);
    formikInjectProps.setFieldValue('contractDueDate', convertedDate);
  }

  return(
    <Grid container justify="center">
      <Box mt={3} mb={4}>
        <Card style={{ overflow: 'visible' }}>
          <CardHeader
            style={{ backgroundColor: '#022A5C', padding: '8px' }}
            title={
              <Typography gutterBottom variant="h6" className={classes.root}>
                Configurações de cobrança
              </Typography>
            }
          />
          <Loader isLoading={isLoading}>
            <CardContent>
              <Grid container spacing={3}>
                {isInvoiceDateVisible ? (
                  <Grid item md={12} xs={12}>
                    <SingleDatePicker
                      name="invoiceDate"
                      label="Escolha a data da primeira fatura"
                      initialDate={formikInjectProps.values.invoiceDate}
                      blockPastDates
                      onBlockedDate={() => toast.error('A data da primeira fatura não pode ser anterior a data atual.')}
                      onDateChange={date => formikInjectProps.setFieldValue('invoiceDate', convertDateToDB(date))}
                    />
                    {formikInjectProps.errors.invoiceDate && (
                      <Typography style={{ color: '#e53935' }}>
                        {formikInjectProps.errors.invoiceDate}
                      </Typography>
                    )}
                  </Grid>
                ) : null}
                <Grid item md={12} xs={12}>
                  <Select
                    name="frequencyId"
                    label="Frequência da fatura"
                    options={availableFrequencies}
                    value={formikInjectProps.values.frequencyId}
                    error={formikInjectProps.errors.frequencyId}
                    helperText={formikInjectProps.errors.frequencyId}
                    onChange={e => handleFrequencySelection(e, formikInjectProps.setFieldValue)}
                  />
                </Grid>
              </Grid>
              <Collapse in={formikInjectProps.values.frequencyId === 1}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Select
                      name="invoiceDueDay"
                      label="Cobrar sempre no dia"
                      options={monthDays}
                      value={formikInjectProps.values.invoiceDueDay}
                      error={formikInjectProps.errors.invoiceDueDay}
                      helperText={formikInjectProps.errors.invoiceDueDay}
                      onChange={e => formikInjectProps.handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              {(+clientTypeId === 4 || +clientTypeId === 5) && (
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Select
                      name="closingDays"
                      label="Fechar a fatura x dias antes do vencimento"
                      options={frequencyIdToOptions(formikInjectProps.values.frequencyId)}
                      value={getClosingDaysValue(formikInjectProps.values.closingDays)}
                      error={formikInjectProps.errors.closingDays}
                      helperText={formikInjectProps.errors.closingDays}
                      onChange={e => formikInjectProps.handleChange(e)}
                    />
                  </Grid>
                </Grid>
              )}
              {(+clientTypeId === 4 || +clientTypeId === 5) && (
                <>
                  {!isEdit && (
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12} style={{ width: 'min-content' }}>
                        {!firstInvoiceDueDate ? (
                          <Typography>
                            <strong>Simulação:</strong> <br />
                            Escolha a data da primeira fatura para calcular a simulação.
                          </Typography>
                        ) : (
                          <Typography>
                            <strong>Simulação:</strong> <br />
                            O seu primeiro relatório vai ser gerado até a data <strong>{firstInvoiceClosingDateLessOneDay.format('DD/MM/YYYY')}</strong>, pois o
                            seu fechamento vai ser <strong>{firstInvoiceClosingDate.format('DD/MM/YYYY')}</strong>. Na próxima fatura, as vendas
                            feitas entre o dia <strong>{firstInvoiceClosingDate.format('DD/MM/YYYY')}</strong> e <strong>{secondInvoiceClosingDateLessOneDay.format('DD/MM/YYYY')}</strong> entrarão
                            no fechamento do dia <strong>{secondInvoiceClosingDate.format('DD/MM/YYYY')}</strong> com vencimento em <strong>{secondInvoiceDueDate.format('DD/MM/YYYY')}</strong>.
                            E assim, por diante.
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <SingleDatePicker
                    name="contractDueDate"
                    label="Validade do contrato"
                    initialDate={
                      createdInvoiceDate && contractDueDate != '0001-01-01'
                        ? convertDateToDB(contractDueDate || formikInjectProps.values.contractDueDate)
                        : null
                    }
                    onDateChange={date => handleContractDueDate(date)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    name="toleranceDays"
                    type="number"
                    label="Dias de tolerância"
                    placeholder="0"
                    value={formikInjectProps.values.toleranceDays}
                    error={formikInjectProps.errors.toleranceDays}
                    helperText={formikInjectProps.errors.toleranceDays}
                    onChange={e => formikInjectProps.handleChange(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Input
                    name="penalty"
                    type="number"
                    label="Multa por atraso"
                    placeholder="0"
                    value={formikInjectProps.values.penalty}
                    error={formikInjectProps.errors.penalty}
                    helperText={formikInjectProps.errors.penalty}
                    onChange={e => handlePenalty(e.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Select
                    name="penaltyType"
                    label="Selecione a tarifa"
                    options={[
                      { label: 'R$', value: 1 },
                      { label: '%', value: 2 },
                    ]}
                    value={formikInjectProps.values.penaltyType}
                    error={formikInjectProps.errors.penaltyType}
                    helperText={formikInjectProps.errors.penaltyType}
                    onChange={e => formikInjectProps.handleChange(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Input
                    name="amount"
                    type="money"
                    label="Valor do contrato"
                    placeholder="R$ 0,00"
                    value={formikInjectProps.values.amount}
                    error={formikInjectProps.errors.amount}
                    helperText={formikInjectProps.errors.amount}
                    onChange={e => handleAmount(e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Loader>
        </Card>
      </Box>
    </Grid>
  );
}

Charges.defaultProps = {
  formikInjectProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      invoiceDate: '',
      invoiceDueDay: '',
      frequencyId: 1,
      contractDueDate: '',
      toleranceDays: '',
      penalty: '',
      penaltyType: '',
      amount: ''
    },
    errors: {
      invoiceDate: false,
      invoiceDueDay: false,
      frequencyId: false,
      contractDueDate: false,
      toleranceDays: false,
      penalty: false,
      penaltyType: false,
      amount: false
    }
  }
};

Charges.propTypes = {
  formikInjectProps: PropTypes.shape()
};