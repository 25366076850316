import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel, InputAdornment, MenuItem, FormHelperText, FormControl, Select, Checkbox, Chip } from '@material-ui/core';

import { filterValueLabel } from '../../helpers/common';
import { useStyles } from './styles';

const SelectComponent = ({ ...props }) => {
  const {
    displayEmpty,
    multiple,
    disabled,
    name,
    label,
    endIcon,
    options,
    value,
    error,
    helperText,
    onChange
  } = props;

  const classes = useStyles(props);
  const labelId = `${name}-label`;

  return(
    <FormControl fullWidth variant="outlined" classes={classes} error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        endAdornment={
          !!endIcon && (
            <InputAdornment className={classes.selectAdornment} position="end">
              {endIcon}
            </InputAdornment>
          )
        }
        multiple={multiple}
        displayEmpty={displayEmpty}
        disabled={disabled}
        name={name}
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        classes={{ select: classes.select, iconOutlined: classes.iconOutlined }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        renderValue={
          multiple
            ? selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      size="small"
                      key={value}
                      label={filterValueLabel(options, value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )
            : () => filterValueLabel(options, value)
        }
      >
        {options && options && options.length > 0 && options.map((option, index) => (
          <MenuItem
            id={`menu-item-${index}`}
            value={option.value}
            disabled={option.disabled}
            selected={option.selected}
          >
            {multiple && (
              <Checkbox checked={value.indexOf(option.value) > -1} />
            )}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

SelectComponent.defaultProps = {
  multiple: false,
  displayEmpty: false,
  disabled: false,
  error: false,
  helperText: '',
  value: '',
  options: [],
  noMargin: false
};

SelectComponent.propTypes = {
  multiple: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  noMargin: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number])
    })
  )
};

export { SelectComponent };