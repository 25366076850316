import React from 'react';
import PropTypes from 'prop-types';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { prop, assoc } from 'ramda';
import { convertToBrl } from 'helpers/converters';

import Header from '../Header';
import Footer from '../Footer';

import { styles } from '../styles';

const col3 = { width: `${100 / 3}%` };
const col4 = { width: `${100 / 4}%` };
const col5 = { width: `${100 / 5}%` };

const CashFlow = ({ cashFlow }) => {
  return(
    <View>
      <View>
        <Text style={styles.title}>Fluxo de caixa</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View
            style={[
              styles.tableColHeader,
              styles.cashFlowCell,
              styles.cashFlowSuccess
            ]}
          >
            <Text style={[styles.title, styles.fontSuccess]}>
              Receita: {convertToBrl(+cashFlow.entry)}
            </Text>
          </View>
          <View
            style={[
              styles.tableColHeader,
              styles.cashFlowCell,
              styles.cashFlowDanger
            ]}
          >
            <Text style={[styles.title, styles.fontDanger]}>
              Despesa: {convertToBrl(+cashFlow.exit)}
            </Text>
          </View>
          <View
            style={[
              styles.tableColHeader,
              styles.cashFlowCell,
              styles.cashFlowPrimary
            ]}
          >
            <Text style={[styles.title, styles.fontPrimary]}>
              Lucro: {convertToBrl(+cashFlow.balance)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const Resume = ({ data, name, type, hasPercentage = true, isDiscount = false, isProduct = false }) => {
  if(!data) {
    return null;
  }

  const formattedData = data?.content?.map(content => {
    return {
      amount: content?.totalAmount
    }
  });

  return(
    <View>
      <View>
        <Text style={styles.title}>{name}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableColHeader, hasPercentage ? col4 : col3]}>
            <Text style={styles.tableCellHeader}>Descrição</Text>
          </View>
          <View style={[styles.tableColHeader, hasPercentage ? col4 : col3]}>
            <Text style={styles.tableCellHeader}>Valor</Text>
          </View>
          <View style={[styles.tableColHeader, hasPercentage ? col4 : col3]}>
            <Text style={styles.tableCellHeader}>Quantidade</Text>
          </View>
          {hasPercentage && (
            <View style={[styles.tableColHeader, hasPercentage ? col4 : col3]}>
              <Text style={styles.tableCellHeader}>%</Text>
            </View>
          )}
        </View>
        {data.content.map(resume => (
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, hasPercentage ? col4 : col3]}>
              {isProduct ? (
                <Text style={styles.tableCell}>{resume.name}</Text>
              ) : (
                <Text style={styles.tableCell}>{prop(type, resume)}</Text>
              )}
            </View>
            <View style={[styles.tableCol, hasPercentage ? col4 : col3]}>
              <Text style={styles.tableCell}>
                {convertToBrl(+resume.totalAmount)}
              </Text>
            </View>
            <View style={[styles.tableCol, hasPercentage ? col4 : col3]}>
              <Text style={styles.tableCell}>{resume.total}</Text>
            </View>
            {hasPercentage && (
              <View style={[styles.tableCol, hasPercentage ? col4 : col3]}>
                <Text style={styles.tableCell}>{resume.percent}%</Text>
              </View>
            )}
          </View>
        ))}
        {isDiscount ? (
          <View style={[styles.footer, { display: 'flex', flexDirection: 'column' }]}>
            <Text style={[styles.text, styles.label]}>
              O valor total de receita acima é resultado da soma dos serviços abaixo menos o valor total do desconto.
            </Text>
          </View>
        ) : (
          <Footer
            data={formattedData}
            total={data.total}
            amount={data.totalAmount}
          />
        )}
      </View>
    </View>
  );
}

const PaymentMethods = ({ data, name }) => {
  const { serviceOrders, services, invoices, manual, orders } = data;

  const createPaymentMethod = (type, name) => {
    if(!type) {
      return null;
    }

    const titleStyle = index => {
      if(index === 0) {
        return [styles.tableCol, col5, { borderBottomWidth: 0 }];
      }

      if(index > 0 && index < type.content.length - 1) {
        return [
          styles.tableCol,
          col5,
          { borderTopWidth: 0, borderBottomWidth: 0 }
        ];
      }

      return [styles.tableCol, col5, { borderTopWidth: 0 }];
    }

    return type.content.map((resume, index) => (
      <View style={styles.tableRow}>
        <View style={[
          titleStyle(index),
          index === (type.content.length - 1) ? styles.tableCellBorderBottom : ''
        ]}>
          <Text style={styles.tableCell}>{index === 0 ? name : ''}</Text>
        </View>
        <View style={[styles.tableCol, col5]}>
          <Text style={styles.tableCell}>{resume.paymentMethodName}</Text>
        </View>
        <View style={[styles.tableCol, col5]}>
          <Text style={styles.tableCell}>
            {convertToBrl(+resume.totalAmount)}
          </Text>
        </View>
        <View style={[styles.tableCol, col5]}>
          <Text style={styles.tableCell}>{resume.total}</Text>
        </View>
        <View style={[styles.tableCol, col5]}>
          <Text style={styles.tableCell}>{resume.percent}%</Text>
        </View>
      </View>
    ));
  }

  return(
    <View>
      <View>
        <Text style={styles.title}>{name}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableColHeader, col5]}>
            <Text style={styles.tableCellHeader}>Tipo</Text>
          </View>
          <View style={[styles.tableColHeader, col5]}>
            <Text style={styles.tableCellHeader}>Descrição</Text>
          </View>
          <View style={[styles.tableColHeader, col5]}>
            <Text style={styles.tableCellHeader}>Valor</Text>
          </View>
          <View style={[styles.tableColHeader, col5]}>
            <Text style={styles.tableCellHeader}>Quantidade</Text>
          </View>
          <View style={[styles.tableColHeader, col5]}>
            <Text style={styles.tableCellHeader}>%</Text>
          </View>
        </View>
        {createPaymentMethod(serviceOrders, 'Rotativo')}
        {createPaymentMethod(services, 'Serviços')}
        {createPaymentMethod(orders, 'Pedidos')}
        {createPaymentMethod(invoices, 'Faturas')}
        {createPaymentMethod(manual, 'Movimentações avulsas')}
      </View>
    </View>
  );
}

const Invoices = ({ data, name }) => {
  const { paidOut } = data.content;
  const invoices = [assoc('label', 'Pagas', paidOut)];

  if(data.total === 0) {
    return false;
  }

  const formattedData = [
    { amount: data?.content?.paidOut?.totalAmount }
  ];

  return(
    <View>
      <View>
        <Text style={styles.title}>{name}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableColHeader, col4]}>
            <Text style={styles.tableCellHeader}>Descrição</Text>
          </View>
          <View style={[styles.tableColHeader, col4]}>
            <Text style={styles.tableCellHeader}>Valor</Text>
          </View>
          <View style={[styles.tableColHeader, col4]}>
            <Text style={styles.tableCellHeader}>Quantidade</Text>
          </View>
          <View style={[styles.tableColHeader, col4]}>
            <Text style={styles.tableCellHeader}>%</Text>
          </View>
        </View>
        {invoices.map(invoice =>
          invoice.totalAmount !== 0 && (
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, col4]}>
                <Text style={styles.tableCell}>{invoice.label}</Text>
              </View>
              <View style={[styles.tableCol, col4]}>
                <Text style={styles.tableCell}>
                  {convertToBrl(+invoice.totalAmount)}
                </Text>
              </View>
              <View style={[styles.tableCol, col4]}>
                <Text style={styles.tableCell}>{invoice.total}</Text>
              </View>
              <View style={[styles.tableCol, col4]}>
                <Text style={styles.tableCell}>{invoice.percent}%</Text>
              </View>
            </View>
          )
        )}
        <Footer
          data={formattedData}
          total={data.total}
          amount={+data.totalAmount}
          totalPeriod="0"
        />
      </View>
    </View>
  );
}

const FinancialResume = ({
  exportData,
  serviceCategories,
  dateRange,
  fileName,
  establishmentName
}) => {
  const {
    cashFlow,
    serviceOrders,
    services,
    products,
    reservations,
    entryCategory,
    exitCategory,
    invoices,
    paymentMethods,
    discounts
  } = exportData;

  const formattedServices = services?.content?.map(service => {
    const categories = service?.serviceCategories?.map(category => +category);

    const selectedCategories = serviceCategories
      ?.filter(category => categories?.includes(+category?.value))
      ?.map(category => category?.label)
      ?.join(', ');

    return {
      ...service,
      description: `${service?.description} ${!!selectedCategories ? `(${selectedCategories})` : ''}`
    }
  });

  return(
    <Document>
      <Page style={styles.body}>
        <Header
          fileName={fileName}
          dateRange={dateRange}
          establishmentName={establishmentName}
        />
        <CashFlow cashFlow={cashFlow} />
        {+discounts?.totalAmount != 0 && (
          <Resume
            name="Descontos"
            type="description"
            data={discounts}
            hasPercentage={false}
            isDiscount
          />
        )}
        {serviceOrders?.totalAmount > 0 && (
          <Resume
            name="Rotativo"
            type="typePrice"
            data={serviceOrders}
          />
        )}
        {services?.content?.length > 0 && (
          <Resume
            name="Serviços"
            type="description"
            data={{ content: formattedServices, total: services?.total, totalAmount: services?.totalAmount }}
          />
        )}
        {products?.content?.length > 0 && (
          <Resume
            name="Produtos"
            type="description"
            data={products}
            hasPercentage={false}
            isProduct
          />
        )}
        <Resume
          name="Reservas"
          type="reservationTypeName"
          data={reservations}
        />
        <Resume
          name="Movimentações de entrada"
          type="categoryName"
          data={entryCategory}
        />
        <Resume
          name="Movimentações de saída"
          type="categoryName"
          data={exitCategory}
        />
        <Invoices
          data={invoices}
          name="Faturas"
        />
        <PaymentMethods
          data={paymentMethods}
          name="Meios de pagamento"
        />
      </Page>
    </Document>
  );
}

FinancialResume.propTypes = {
  exportData: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fileName: PropTypes.string.isRequired,
  establishmentName: PropTypes.string.isRequired
};

CashFlow.propTypes = {
  cashFlow: PropTypes.shape({
    entry: PropTypes.oneOf([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired
    ]).isRequired,
    exit: PropTypes.oneOf([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired
    ]).isRequired,
    balance: PropTypes.oneOf([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired
    ]).isRequired
  }).isRequired
};

Resume.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

PaymentMethods.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  name: PropTypes.string.isRequired
};

Invoices.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape.isRequired).isRequired,
  name: PropTypes.string.isRequired
};

export default FinancialResume;