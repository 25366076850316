import React from 'react';
import { NumberFormatBase } from 'react-number-format';
import { TextField } from '@material-ui/core';

const CustomTextField = ({ ...props }) => (
  <TextField
    {...props}
    fullWidth
    variant="outlined"
  />
);

export default function NumericInput({
  value,
  onChange,
  hasMaxValue = false,
  maxValue,
  ...props
}) {
  const currencyFormatter = value => {
    if(!value) {
      return "";
    }

    const number = Number(value);

    const amount =
      new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
      .format(number / 100)
      .replace("R$", "")
      .trim();

    return `${amount}`;
  }

  return(
    <NumberFormatBase
      format={currencyFormatter}
      customInput={CustomTextField}
      fixedDecimalScale
      decimalScale={2}
      decimalSeparator=","
      thousandSeparator="."
      type="text"
      value={value}
      isAllowed={({ formattedValue }) => {
        const value = formattedValue.replaceAll(".", "").replaceAll(",", ".");

        if(hasMaxValue) {
          return value <= maxValue;
        }

        return value;
      }}
      onValueChange={({ formattedValue }) => {
        const value = formattedValue.replaceAll(".", "").replaceAll(",", ".");
        return onChange(value);
      }}
      {...props}
    />
  );
}