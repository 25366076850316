import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Formik, Form } from 'formik';
import { Grid, Switch, Typography, FormControlLabel, Checkbox } from '@material-ui/core';

import Drawer from '../../../../components/Drawer';
import Modal from '../../../../components/Modal';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ButtonConfirmation from '../../../../components/ButtonConfirmation';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Select from '../../../../components/Select';
import FileUploader from '../../../../components/FileUploader';
import WarningMessage from '../../../../components/WarningMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faCheck } from '@fortawesome/free-solid-svg-icons';

import { receiptValidations, passwordValidations } from '../validations';
import { setAttachment } from '../../store/actions';
import { sendNfse } from 'pages/ProvisionalReceiptConfig/store/thunk';
import { loadNfseConcept } from 'pages/ProvisionalReceipt/store/thunk';
import { setRpsInfo } from 'pages/ProvisionalReceipt/store/actions';
import { fetchUsersSituation } from 'components/Filter/store/services';
import { upsertTransaction, removeTransaction, upsertAttachment, loadResume } from '../../store/thunk';
import { convertToOptions, convertDateToDB, convertFormattedMoneyToNumber, convertFormattedMoneyAndNegativeMoneyToNumber, convertToBrl } from '../../../../helpers/converters';
import { getFileExtensionFromUrl } from '../../../../helpers/common';

import {
  handleRegistration,
  clearRegistration,
  setRemove,
  clearChanges
} from '../../store/actions';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

import { SITE_RESTRICTIONS } from '../../../../helpers/restrictions';

export default function Receipt({ isOpen }) {
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const isSm = width < 960;
  const isMobile = width < 728;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { token } = useSelector(state => state.login);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp, selects } = useSelector(state => state.filters);
  const { submit, isConfirm, isRemoving, isLoading } = useSelector(state => state.transactions);
  const { isSendNfseLoading } = useSelector(state => state.provisionalReceiptConfig);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);

  const {
    sourceId,
    isNfInfo,
    nfInfo: {
      rpsSeries,
      rpsNumber,
      nfNumber
    }
  } = useSelector(state => state.provisionalReceipt);

  const { cashierId, transactionId, situationId, attachment, paymentDateTime } = submit;

  const isFiveDaysPastFromPayment =
    moment(moment.now()).diff(moment(paymentDateTime, 'YYYY-MM-DD'), 'days') > 5;

  const [confirmResendNfse, setConfirmResendNfse] = useState(false);
  const [taxSettings, setTaxSettings] = useState(0);

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [attachmentRemoveLoading, setAttachmentRemoveLoading] = useState(false);
  const [isAttachmentError, setIsAttachmentError] = useState(false);

  const [isTransactionReceived, setIsTransactionReceived] = useState(false);
  const [isInvalidAmount, setIsInvalidAmount] = useState(false);

  const availableAccounts = selects
    ?.find(select => select?.id == 'account')?.options
    ?.filter(account => +account?.accountTypeId != 1);

  const receiptCategories = selects
    ?.find(select => select?.id == 'receiptCategory')?.options
    ?.map(option => {
      return {
        value: option?.categoryId,
        label: option?.categoryName
      }
    })
    ?.filter(option => option?.value);

  const availablePaymentMethods = convertToOptions(['paymentMethod'], selects);

  const isOnlyOneAccountAvailable = availableAccounts
    ?.filter(account => !!account?.value)
    ?.length == 1;

  useEffect(() => {
    return () => dispatch(clearRegistration());
  }, []);

  useEffect(() => {
    setIsTransactionReceived(isConfirm);
  }, [isConfirm]);

  useEffect(() => {
    (async () => {
      if(submit?.establishmentId) {
        const { data } = await fetchUsersSituation(userId, token);

        const establishmentTaxSettings = data?.establishments
          ?.find(establishment => +establishment?.establishmentId == +submit?.establishmentId)
          ?.taxSettings;

        setTaxSettings(establishmentTaxSettings);
      }
    })();
  }, [submit?.establishmentId]);

  useEffect(() => {
    dispatch(setRpsInfo({
      rpsSeries: '',
      rpsNumber: '',
      nfNumber: '',
      nfStatus: ''
    }));

    if(+taxSettings == 1 && !!transactionId && (+situationId == 2 || +situationId == 3)) {
      if(sourceId || transactionId) {
        dispatch(loadNfseConcept(userId, establishmentId, sourceId || transactionId));
      }
    }
  }, [taxSettings, transactionId, situationId]);

  const getOnlyAccountAvailable = () => {
    const account = availableAccounts?.filter(account => !!account?.value)[0]?.value || '';
    return account;
  }

  const handleCloseReceipt = () => {
    dispatch(handleRegistration(false));
  }

  const handleUploadFile = e => {
    const receipt = e.target.files[0];

    dispatch(upsertAttachment(userId, establishmentId, receipt))
      .then(() => toast.warn('Salvar para persistir o comprovante!'))
      .catch(() => dispatch(setAttachment(null)));
  }

  const handleRemoveReceipt = data => {
    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate
    }

    const params = { transactionId, ...data }

    dispatch(removeTransaction(params, extraProps));
  }

  const handleSaveReceipt = (values, actions) => {
    const { resetForm } = actions;

    const extraProps = {
      userId,
      establishmentId,
      page,
      pageSize,
      startDate,
      endDate
    }

    const currentTime = moment().format('HH:mm:ss');

    const params = {
      ...values,
      isConfirm: isTransactionReceived,
      previsionDateTime: `${moment(values.previsionDateTime).format('YYYY-MM-DD')} ${currentTime}`,
      paymentDateTime: `${moment(values.paymentDateTime).format('YYYY-MM-DD')} ${currentTime}`,
      confirmationDateTime: `${moment(values.confirmationDateTime).format('YYYY-MM-DD')} ${currentTime}`,
      transactionDateTime: `${moment(values.transactionDateTime).format('YYYY-MM-DD')} ${currentTime}`,
      attachment
    }

    if(isTransactionReceived && !params?.paymentMethodId) {
      return;
    }

    if(+convertFormattedMoneyAndNegativeMoneyToNumber(params?.amount) == 0) {
      setIsInvalidAmount(true);
      return;
    }

    return dispatch(upsertTransaction(params, extraProps)).then(() => {
      resetForm();
      dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp }));
    });
  }

  return(
    <Formik
      enableReinitialize={!!transactionId}
      initialValues={{
        ...submit,
        amount: convertToBrl(convertFormattedMoneyToNumber(submit?.amount)),
        accountId: isOnlyOneAccountAvailable && !transactionId
          ? getOnlyAccountAvailable()
          : submit?.accountId
      }}
      validationSchema={receiptValidations}
      onSubmit={handleSaveReceipt}
    >
      {({ errors, values, handleChange, setFieldValue }) => (
        <Drawer
          id="receipt-modal"
          title={transactionId ? 'Editar receita' : 'Adicionar receita'}
          open={isOpen}
          onClose={handleCloseReceipt}
        >
          {isAttachmentModalOpen && (
            <Modal
              scroll="body"
              open={isAttachmentModalOpen}
              onClose={() => setIsAttachmentModalOpen(false)}
              title="Comprovante"
            >
              <Grid container justify="center" style={{ padding: 5 }}>
                {isAttachmentError ? (
                  <Typography>
                    Pré visualização não disponível. <a href={attachment} target='_blank'>Clique aqui para fazer o download do {getFileExtensionFromUrl(attachment)}.</a>
                  </Typography>
                ) : (
                  <img
                    src={attachment}
                    width={isSm ? "100%" : "50%"}
                    height="auto"
                    onError={() => setIsAttachmentError(true)}
                  />
                )}
              </Grid>
              <Grid container style={{ marginTop: 10 }}>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Button
                    fullWidth
                    type="button"
                    onClick={() => setIsAttachmentModalOpen(false)}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Button
                    fullWidth
                    type="button"
                    color="error"
                    disabled={userRestrictions?.includes(+SITE_RESTRICTIONS.REMOVE_TRANSACTION_ATTACHMENT_AND_CASHIER)}
                    loading={attachmentRemoveLoading}
                    onClick={() => {
                      setAttachmentRemoveLoading(true);

                      return setTimeout(() => {
                        toast.warn('Salvar para persistir o comprovante!');

                        dispatch(setAttachment(null));

                        setIsAttachmentModalOpen(false);
                        setAttachmentRemoveLoading(false);
                      }, 1000);
                    }}
                  >
                    Excluir
                  </Button>
                </Grid>
              </Grid>
            </Modal>
          )}
          <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
              <Loader isLoading={isLoading}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Input
                      autoFocus
                      id="description"
                      name="description"
                      label="Descrição"
                      value={values.description}
                      error={errors.description}
                      helperText={errors.description}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      name="categoryId"
                      label="Categoria"
                      options={receiptCategories}
                      value={parseInt(values.categoryId, 10)}
                      error={errors.categoryId}
                      helperText={errors.categoryId}
                      onChange={e => setFieldValue(e.target.name, e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="primary">
                      Data de vencimento
                    </Typography>
                    {values.transactionId && (
                      <SingleDatePicker
                        name="previsionDateTime"
                        initialDate={values.transactionId ? values.previsionDateTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                        onDateChange={date => setFieldValue('previsionDateTime', convertDateToDB(date))}
                      />
                    )}
                    {!values.transactionId && (
                      <SingleDatePicker
                        name="previsionDateTime"
                        initialDate={values.previsionDateTime}
                        onDateChange={date => setFieldValue('previsionDateTime', convertDateToDB(date))}
                      />
                    )}
                  </Grid>
                  {(cashierId == null) && (
                    <Grid item xs={12}>
                      <Select
                        name="accountId"
                        label="Conta de destino"
                        options={availableAccounts}
                        disabled={cashierId}
                        value={parseInt(values.accountId, 10)}
                        error={errors.accountId}
                        helperText={errors.accountId}
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Input
                      id="amount"
                      name="amount"
                      label="Valor"
                      type="money"
                      placeholder="R$ 0,00"
                      value={values.amount}
                      error={errors.amount || isInvalidAmount}
                      helperText={errors.amount || (isInvalidAmount && 'O valor precisa ser maior que zero')}
                      onChange={handleChange}
                      maxLength={13}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <FormControlLabel
                        label="Recebido"
                        control={
                          <Switch
                            id="received"
                            name="received"
                            color="primary"
                            onChange={() => setIsTransactionReceived(!isTransactionReceived)}
                            checked={isTransactionReceived}
                          />
                        }
                      />
                      {submit?.userName && (
                        <Typography>Transação realizada por: {submit?.userName}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  {rpsNumber > 0 && (
                    <Grid item sm={6} xs={12} style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
                      <p>RPS: {rpsSeries}-{rpsNumber} | N˚ NFSe: {nfNumber}</p>
                    </Grid>
                  )}
                </Grid>
                {isTransactionReceived && (
                  <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color="primary">
                        Data da receita
                      </Typography>
                      {values.transactionId && (
                        <SingleDatePicker
                          name="paymentDateTime"
                          blockFutureDates
                          onBlockedDate={() => toast.error('A data da receita não pode ser maior do que a data atual.')}
                          initialDate={values.transactionId ? values.paymentDateTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                          onDateChange={date => setFieldValue('paymentDateTime', convertDateToDB(date))}
                        />
                      )}
                      {!values.transactionId && (
                        <SingleDatePicker
                          name="paymentDateTime"
                          blockFutureDates
                          onBlockedDate={() => toast.error('A data da receita não pode ser maior do que a data atual.')}
                          initialDate={values.paymentDateTime}
                          onDateChange={date => setFieldValue('paymentDateTime', convertDateToDB(date))}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name="paymentMethodId"
                        label="Método de pagamento"
                        options={availablePaymentMethods}
                        value={parseInt(values.paymentMethodId, 10)}
                        error={isTransactionReceived && !values.paymentMethodId}
                        helperText='Selecione um método de pagamento'
                        onChange={e => setFieldValue(e.target.name, e.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Loader>
              {isRemoving && (
                <Formik
                  enableReinitialize
                  initialValues={{ reason: '', userPassword: '' }}
                  validationSchema={passwordValidations}
                  onSubmit={handleRemoveReceipt}
                >
                  {newFormik => (
                    <Form>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA', marginTop: 10 }}>
                            <b>Remoção</b>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            id="reason"
                            type="textarea"
                            label="Observação"
                            placeholder="Digite sua observação"
                            error={newFormik.errors.reason}
                            helperText={newFormik.errors.reason}
                            value={newFormik.values.reason}
                            onChange={newFormik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            id="userPassword"
                            type="password"
                            label="Informe a sua senha"
                            placeholder="Digite sua senha"
                            error={newFormik.errors.userPassword}
                            helperText={newFormik.errors.userPassword}
                            value={newFormik.values.userPassword}
                            onChange={newFormik.handleChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                        <Button
                          type="button"
                          color="tertiary"
                          variant="outlined"
                          loading={isLoading}
                          onClick={() => dispatch(clearChanges())}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color="success"
                          loading={isLoading}
                        >
                          Concluir
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
              {(!isNfInfo && transactionId) && (
                <>
                  {isFiveDaysPastFromPayment ? (
                    <ButtonConfirmation
                      title="Enviar NFS-e"
                      color="success"
                      disabled={isLoading}
                      isButtonDisabled={!confirmResendNfse}
                      loading={isLoading || isSendNfseLoading}
                      modalTitle="Enviar NFS-e"
                      modalText={
                        <>
                          <Typography color="primary">
                            Tem certeza que deseja enviar a nota fiscal?
                          </Typography>
                          <br />
                          {isFiveDaysPastFromPayment && (
                            <Grid>
                              <WarningMessage message="Atenção! Mais de 5 dias se passaram desde o pagamento, fique atento a uma possível cobrança de multa por parte da prefeitura." />
                              <FormControlLabel
                                labelPlacement="end"
                                label={
                                  <Typography color="primary">
                                    Entendi
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    value={confirmResendNfse}
                                    onChange={event => setConfirmResendNfse(event.target.checked)}
                                  />
                                }
                              />
                            </Grid>
                          )}
                        </>
                      }
                      modalButtons={[
                        {
                          color: 'success',
                          title: 'Sim',
                          onClick: () => dispatch(sendNfse(userId, submit?.establishmentId, transactionId, { resend: 1 }))
                        }
                      ]}
                    />
                  ) : (
                    <Button
                      color="success"
                      type="button"
                      loading={isLoading || isSendNfseLoading}
                      disabled={isLoading}
                      onClick={() => dispatch(sendNfse(userId, submit?.establishmentId, transactionId, { resend: 1 }))}
                    >
                      Enviar NFS-e
                    </Button>
                  )}
                </>
              )}
              {!attachment && (
                <FileUploader
                  name="attachment"
                  color="tertiary"
                  variant="outlined"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="xs"
                    />
                  }
                  displayFileName={false}
                  onChange={e => handleUploadFile(e)}
                >
                  Comprovante
                </FileUploader>
              )}
              {!!attachment && (
                <Button
                  type="button"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faCheck}
                      color="#6DBB7B"
                      size="xs"
                    />
                  }
                  onClick={() => setIsAttachmentModalOpen(true)}
                >
                  Comprovante
                </Button>
              )}
              {transactionId && (
                <Button
                  type="button"
                  color="error"
                  loading={isLoading}
                  onClick={() => dispatch(setRemove())}
                  disabled={userRestrictions?.includes(SITE_RESTRICTIONS.SINGLE_TRANSACTIONS)}
                >
                  Remover receita
                </Button>
              )}
              <Button
                color="success"
                loading={isLoading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </Drawer>
      )}
    </Formik>
  );
}

Receipt.propTypes = {
  isOpen: PropTypes.bool.isRequired
};