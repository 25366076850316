import { lensProp, set } from 'ramda';
import { formatServiceOrders } from '../../../helpers/formatters';

import { SET_SERVICE_ORDERS, SET_RESUME_SERVICE_ORDERS, HANDLE_LOADING, HANDLE_RESUME_LOADING, TOGGLE_SERVICE_ORDER } from './constants';

const defaultState = {
  serviceOrders: [],
  total: null,
  modal: false,
  isLoading: false,
  isResumeLoading: false,
  resume: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SERVICE_ORDERS: {
      const { total } = action;
      return {
        ...state,
        serviceOrders: formatServiceOrders(action.data),
        total,
        isLoading: false
      }
    }
    case SET_RESUME_SERVICE_ORDERS: {
      const { resume } = action;
      return {
        ...state,
        resume
      }
    }
    case TOGGLE_SERVICE_ORDER: {
      return set(lensProp('modal'), action.status, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_RESUME_LOADING: {
      return set(lensProp('isResumeLoading'), action.status, state);
    }
    default: {
      return state;
    }
  }
}