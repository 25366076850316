export const SET_RPS = 'RPS/SET_RPS';
export const SET_RPS_INFO = 'RPS/SET_RPS_INFO';
export const SET_IS_NF_INFO = 'RPS/SET_IS_NF_INFO';
export const SET_SOURCE_ID = 'RPS/SET_SOURCE_ID';
export const SET_SELECTED_NF_WITH_ERRORS = 'RPS/SET_SELECTED_NF_WITH_ERRORS';
export const SET_SELECTED_SINGLE_NF_WITH_ERRORS = 'RPS/SET_SELECTED_SINGLE_NF_WITH_ERRORS';
export const SET_SELECTED_SINGLE_NF_WITH_ERRORS_PAYMENT_DATE_TIME = 'RPS/SET_SELECTED_SINGLE_NF_WITH_ERRORS_PAYMENT_DATE_TIME';
export const CLEAR_SELECTED_PAGE_NF_WITH_ERRORS = 'RPS/CLEAR_SELECTED_PAGE_NF_WITH_ERRORS';
export const CLEAR_SELECTED_NF_WITH_ERRORS = 'RPS/CLEAR_SELECTED_NF_WITH_ERRORS';
export const SET_IS_NF_SEND_MODAL_OPEN = 'RPS/SET_IS_NF_SEND_MODAL_OPEN';
export const HANDLE_LOADING = 'RPS/HANDLE_LOADING';
export const HANDLE_ERROR = 'RPS/HANDLE_ERROR';