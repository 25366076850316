import * as types from './constants';

export const setProductFilters = filters => ({
  type: types.SET_PRODUCT_FILTERS,
  filters
});

export const setProductList = data => ({
  type: types.SET_PRODUCT_LIST,
  data
});

export const setProductListLoading = isLoading => ({
  type: types.SET_PRODUCT_LIST_LOADING,
  isLoading
});

export const setIsConsumeList = isConsume => ({
  type: types.SET_IS_CONSUME_LIST,
  isConsume
});

export const setIsSellList = isSell => ({
  type: types.SET_IS_SELL_LIST,
  isSell
});