import { toast } from 'react-toastify';

import { loadComissionedPayrollListSettings } from 'pages/ComissionedPayrollList/store/thunk';

import {
  setIsLoading,
  setComissionedPayroll,
  setComissionedPayrollErrors,
  clearComissionedPayrollErrors
} from './actions';

import {
  fetchComissionedPayroll,
  createComissionedPayroll,
  updateComissionedPayroll
} from './services';

export const loadComissionedPayroll = (userId, establishmentId, uuid) => dispatch => {
  dispatch(setIsLoading(true));
  
  return fetchComissionedPayroll(userId, establishmentId, uuid)
    .then(({ data }) => dispatch(setComissionedPayroll(data)))
    .finally(() => dispatch(setIsLoading(false)));
}

export const upsertComissionedPayroll = (userId, establishmentId, uuid, params, onClose, extraProps) => dispatch => {
  dispatch(setIsLoading(true));

  const {
    page,
    limit,
    qp
  } = extraProps;

  if(!uuid) {
    return createComissionedPayroll(userId, establishmentId, params)
      .then(({ data }) => {
        toast.success('Comissionado criado com sucesso.');
        dispatch(setComissionedPayroll(data));
        dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page, limit, qp }));
        return onClose();
      })
      .catch(error => {
        if(!error.errors) {
          dispatch(clearComissionedPayrollErrors());
          toast.error('Houve um erro ao criar o comissionado.');
        }

        dispatch(setComissionedPayrollErrors(error.errors));
      })
      .finally(() => dispatch(setIsLoading(false)));
  }

  return updateComissionedPayroll(userId, establishmentId, uuid, params)
    .then(({ data }) => {
      toast.success('Comissionado atualizado com sucesso.');
      dispatch(setComissionedPayroll(data));
      dispatch(loadComissionedPayrollListSettings({ userId, establishmentId, page, limit, qp }));
      return onClose();
    })
    .catch(error => {
      if(!error.errors) {
        dispatch(clearComissionedPayrollErrors());
        toast.error('Houve um erro ao atualizar o comissionado.');
      }

      dispatch(setComissionedPayrollErrors(error.errors));
    })
    .finally(() => dispatch(setIsLoading(false)));
}