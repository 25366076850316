import { toast } from 'react-toastify';
import { omit } from 'ramda';

import {
  fetchProvisionalReceiptTables,
  createRPSConfig,
  updateRPSConfig,
  requestToUpdateCertificate,
  requestToUpdatePassword,
  requestToUpdateToken,
  fetchUpdateFilters,
  requestToUpdateFilters,
  fetchProvisionalReceiptInfo,
  fetchEconomicActivities,
  requestToUpdateLogo,
  fetchConfigRps,
  createConfigRps,
  loadRegimeEstadual,
  requestUpdateRegimeEstadual,
  fetchEstablishmentsWithConfig,
  requestAttachEstablishment,
  requestDetachEstablishment,
  requetSendNfse,
  requestConfirmTermsOfUse
} from './service';

import {
  setTables,
  setProvisionalReceiptInfo,
  setProvisionalReceiptErrors,
  clearProvisionalReceiptErrors,
  setEconomicActivities,
  setLogoFile,
  handleLoading,
  handleCertificateLoading,
  handleRestrictionsLoading,
  clearCertificate,
  clearProvisionalReceiptInfo,
  setEstablishmentsWithConfig,
  setLastRpsSuccess,
  isRpsConfig,
  isSendNfseLoading,
  setAuthentication,
  setCertificate,
  setRpsConfig,
  setRpsFilter,
  setIsTermsOfUseLoading
} from './actions';

import { setIsTermsOfUseChecked } from 'pages/Login/store/actions';

export const updateLogo = (userId, establishmentId, params) => dispatch => {
  const formData = new FormData();
  formData.append('logoFile', params);

  return requestToUpdateLogo(userId, establishmentId, formData).then(({ data }) => {
    dispatch(setLogoFile(data));
  });
}

export const loadProvisionalReceiptTables = userId => dispatch => {
  return fetchProvisionalReceiptTables(userId)
    .then(({ data }) => dispatch(setTables(data)));
}

export const loadProvisionalReceiptInfo = (userId, establishmentId) => dispatch => {
  dispatch(clearProvisionalReceiptInfo());

  dispatch(handleLoading(true));

  return fetchProvisionalReceiptInfo(userId, establishmentId)
    .then((res) => {
      dispatch(setProvisionalReceiptInfo(res.data));
      dispatch(setLastRpsSuccess(res.lastRpsSuccess));
      dispatch(isRpsConfig(true));
    })
    .catch(error => {
      if(error.response && error.response.data.data.code === '17001') {
        toast.warn('Estabelecimento sem configuração de nota.');
        dispatch(clearProvisionalReceiptInfo());
        dispatch(isRpsConfig(false));
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadEconomicActivities = userId => dispatch => {
  dispatch(handleLoading(true));

  return fetchEconomicActivities(userId)
    .then(({ data }) => dispatch(setEconomicActivities(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const createRps = (userId, establishmentId, params) => dispatch => {
  dispatch(handleLoading(true));

  const formatParams = omit(['password', 'passwordConfirm', 'logo', 'userId', 'establishmentId'], params);

  return createRPSConfig(userId, establishmentId, formatParams)
    .then(() => {
      toast.success('Configuração de nota fiscal criada com sucesso!');
      dispatch(clearProvisionalReceiptErrors());
      return Promise.resolve();
    })
    .catch((error) => {
      if(error) {
        dispatch(setProvisionalReceiptErrors(error));
      }

      if(+error?.response?.status == 404) {
        toast.error(error?.response?.data?.error?.message);
      }

      return Promise.reject();
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertRPS = (userId, establishmentId, params) => dispatch => {
  dispatch(handleLoading(true));

  if(params?.logo) {
    dispatch(updateLogo(userId, establishmentId, params?.logo));
  }

  const formatParams = omit(['password', 'passwordConfirm', 'logo', 'userId', 'establishmentId'], params);

  return updateRPSConfig(userId, establishmentId, formatParams)
    .then(() => {
      toast.success('Nota fiscal atualizada com sucesso!');
      dispatch(clearProvisionalReceiptErrors());
      return Promise.resolve();
    })
    .catch((error) => {
      if(error) {
        dispatch(setProvisionalReceiptErrors(error));
      }

      if(error?.response?.data) {
        toast.error(error?.response?.data);
      }

      if(error?.response?.data?.data?.message) {
        toast.error(error?.response?.data?.data?.message);
      }

      if(+error?.response?.status == 404) {
        toast.error(error?.response?.data?.error?.message);
      }

      return Promise.reject();
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const updateCertificate = params => dispatch => {
  dispatch(handleCertificateLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateCertificate(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success("Autenticação concluída com sucesso");

      dispatch(setCertificate(data));
      dispatch(clearCertificate());

      return Promise.resolve();
    })
    .catch(error => {
      toast.error(error?.response?.data?.data?.message);
      return Promise.reject();
    })
    .finally(() => dispatch(handleCertificateLoading(false)));
}

export const updateConfigRps = params => dispatch => {
  dispatch(handleCertificateLoading(true));

  return requestToUpdateCertificate(params)
    .then(({ data }) => toast.success(data.msg))
    .finally(() => dispatch(handleCertificateLoading(false)));
}

export const updatePassword = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdatePassword(userId, establishmentId, params)
    .then(() => {
      toast.success("Autenticação concluída com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const updateToken = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateToken(userId, establishmentId, params)
    .then(() => {
      toast.success("Autenticação concluída com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const loadUpdateFilters = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchUpdateFilters(userId, establishmentId)
    .then(({ data }) => {
      if(data?.response == "error") {
        return toast.error(data?.data);
      }

      dispatch(setRpsFilter(data));
    })
    .catch(error => {
      if(error?.response?.data?.data?.code == "17001") {
        return toast.error(error?.response?.data?.data?.msg);
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const updateFilters = (userId, establishmentId, params) => dispatch => {
  dispatch(handleLoading(true));

  return requestToUpdateFilters(userId, establishmentId, params)
    .then(() => {
      toast.success("Configuração do filtro atualizada com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const loadConfigRps = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchConfigRps(userId, establishmentId)
    .then(({ data }) => {
      if(data?.response == "error") {
        return toast.error(data?.data);
      }

      dispatch(setRpsConfig(data));
    })
    .catch(error => {
      if(error?.response?.data?.data?.code == "17001") {
        return toast.error(error?.response?.data?.data?.msg);
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertConfigRps = (userId, establishmentId, params) => dispatch => {
  dispatch(handleLoading(true));

  return createConfigRps(userId, establishmentId, params)
    .then(({ data }) => {
      dispatch(setRpsConfig(data));
      toast.success("Configuração atualizada com sucesso.");
      return Promise.resolve();
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadRegime = (userId, codigoIbge) => dispatch => {
  return loadRegimeEstadual(userId, codigoIbge)
    .then(data => {
      dispatch(setAuthentication(data));
      return data;
    })
    .catch(() => toast.error('Problemas no servidor, tente novamente mais tarde'));
}

export const updateRegime = (userId, codigoIbge, params) => dispatch => {
  dispatch(handleRestrictionsLoading(true));

  return requestUpdateRegimeEstadual(userId, codigoIbge, params)
    .then(() => toast.success('Restrições atualizadas com sucesso!'))
    .catch(() => toast.error('Não foi possível atualizar as restrições.'))
    .finally(() => dispatch(handleRestrictionsLoading(false)));
}

export const loadEstablishmentsWithConfig = userId => dispatch => {
  dispatch(handleLoading(true));

  return fetchEstablishmentsWithConfig(userId)
    .then(({ data }) => dispatch(setEstablishmentsWithConfig(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const attachEstablishment = (userId, establishmentId, taxSettingUuid) => dispatch => {
  dispatch(handleLoading(true));

  return requestAttachEstablishment(userId, establishmentId, taxSettingUuid)
    .then(() => Promise.resolve())
    .catch(() => toast.error('Houve um problema ao vincular o estabelecimento.'))
    .finally(() => dispatch(handleLoading(false)));
}

export const detachEstablishment = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return requestDetachEstablishment(userId, establishmentId)
    .then(() => Promise.resolve())
    .catch(() => toast.error('Houve um problema ao desvincular o estabelecimento.'))
    .finally(() => dispatch(handleLoading(false)));
}

export const sendNfse = (userId, establishmentId, transactionId, params) => dispatch => {
  dispatch(isSendNfseLoading(true));

  return requetSendNfse(userId, establishmentId, transactionId, params)
    .then(() => toast.success('NFS-e enviada com sucesso.'))
    .catch(() => toast.error('Não foi possível enviar a NFS-e.'))
    .finally(() => dispatch(isSendNfseLoading(false)));
}

export const confirmTermsOfUse = userId => dispatch => {
  dispatch(setIsTermsOfUseLoading(true));

  return requestConfirmTermsOfUse(userId)
    .then(({ data }) => {
      toast.success(data);
      dispatch(setIsTermsOfUseChecked(true));
      return Promise.resolve();
    })
    .finally(() => dispatch(setIsTermsOfUseLoading(false)));
}